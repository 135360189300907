<template>
  <div :class="[
      'vue-base-dialog-mask',
      mask ? '' : 'vue-base-dialog-mask-hide',
      maskAnimation,
    ]" @click.self="dialogConfig.maskClick ? close() : false">
    <div :class="['vue-base-dialog-box', dialogAnimation]">
      <div v-if="!dialogConfig.selHeader" :class="[
          'vue-base-dialog-header',
          dialogConfig.drag ? 'vue-base-dialog-drag' : '',
        ]" @mousedown.self="dialogConfig.drag ? mouseDown($event) : false" @mouseup.self="dialogConfig.drag ? mouseUp() : false">
        <span>{{ dialogConfig.title }}</span>
        <i class="close" @click.stop="close()"></i>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "VueBaseDialog",
  props: {
    dialogConfig: {
      type: Object,
    },
  },
  data () {
    return {
      maskAnimation: null,
      dialogAnimation: null,
      mousemoveCallBack: null,
      titleBox: null,
      parentDom: null,
      pointX: null,
      pointY: null,
      boxX: null,
      boxY: null,
      mask: true,
    };
  },
  created () {
    this.maskAnimation = "mask-enter-active";
    this.dialogAnimation = this.dialogConfig.animationEnter
      ? this.dialogConfig.animationEnter
      : "dialog-enter-active";
    if (typeof this.dialogConfig.mask === "undefined") {
      this.mask = true;
    } else {
      if (this.dialogConfig.mask) {
        this.mask = true;
      } else {
        this.mask = false;
      }
    }
  },
  methods: {
    mouseDown (event) {
      let length = document.getElementsByClassName(
        "vue-base-dialog-header"
      ).length;
      // 获取弹框头部
      this.titleBox = document.getElementsByClassName("vue-base-dialog-header")[
        length - 1
      ];
      // 获取整个弹框
      this.parentDom = this.titleBox.parentNode;
      // 记录鼠标点击位置
      this.pointX = event.clientX;
      this.pointY = event.clientY;
      // 记录弹框初始位置
      this.boxX = this.parentDom.offsetLeft;
      this.boxY = this.parentDom.offsetTop;
      // 设置弹框初始位置并改变定位
      this.parentDom.style.left = this.boxX + "px";
      this.parentDom.style.top = this.boxY + "px";
      this.parentDom.style.position = "inherit";
      // 鼠标移动，改变弹框位置
      this.mousemoveCallBack = (event) => {
        this.parentDom.style.top =
          this.boxY + event.clientY - this.pointY + "px";
        this.parentDom.style.left =
          this.boxX + event.clientX - this.pointX + "px";
      };
      // 监听鼠标移动
      document.addEventListener("mousemove", this.mousemoveCallBack);
    },
    mouseUp () {
      // 兼容ie,将定位方式改为absolute
      this.parentDom.style.position = "absolute";
      // 销毁监听的事件
      document.removeEventListener("mousemove", this.mousemoveCallBack);
      this.mousemoveCallBack = null;
    },
    close () {
      this.$emit("close");
      this.maskAnimation = "mask-leave-active";
      this.dialogAnimation = this.dialogConfig.animationLeave
        ? this.dialogConfig.animationLeave
        : "dialog-leave-active";
      setTimeout(
        () => {
          this.$parent.dialogConfig.name = "";
        },
        this.dialogConfig.destroyTime ? this.dialogConfig.destroyTime : 300
      );
    },
  },
  destroyed () {
    this.$emit("closed");
  },
};
</script>

<style scoped lang="less">
.vue-base-dialog-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  perspective: 1200px;
}
.vue-base-dialog-mask:after {
  content: '';
  display: inline-block;
  height: 100vh;
  width: 0;
  vertical-align: middle;
}
.vue-base-dialog-mask-hide {
  background-color: rgba(0, 0, 0, 0);
}
.vue-base-dialog-box {
  margin: 0;
  padding: 0;
  width: max-content;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
}
.vue-base-dialog-header {
  height: 60px;
  font-size: 16px;
  color: #0F1A2E;
  line-height: 60px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  background: linear-gradient(180deg, #EAF0FD 0%, #FFFFFF 100%);
  border-radius: 6px 6px 0px 0px; 
  span {
    font-size: 16px;
  }
}
.vue-base-dialog-header .close {
  float: right;
  width: 14px;
  height: 14px;
  background-image: url('../../assets/imgs/worker/gray-close.png');
  background-size: 100% 100%;
  margin-top: 23px;
  cursor: pointer;
}
.vue-base-dialog-drag {
  cursor: move;
}
.mask-enter-active {
  animation: opacityShow 0.3s;
  animation-fill-mode: forwards;
}
.mask-leave-active {
  animation: opacityHide 0.3s;
  animation-fill-mode: forwards;
}
@keyframes opacityShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacityHide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dialog-enter-active {
  animation: enterActive 0.3s;
}
.dialog-leave-active {
  animation: leaveActive 0.3s;
}
@keyframes enterActive {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
    -moz-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes leaveActive {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
    -moz-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    transform: translateY(-20%);
  }
}
</style>