// import { Message } from "element-ui";
import axios from "axios";
// import store from '../../store'
// import VueCookies from 'vue-cookies';
import router from '@/router';
axios.defaults.withCredentials = true;  //允许跨域携带cookies

var HOST = process.env.VUE_APP_HOST;
var JIAOYU_CN = process.env.VUE_APP_JIAOYU_CN; // 中教云课官网
var JIAOYU_SCHOOL = process.env.VUE_APP_JIAOYU_SCHOOL; // 院校主页
var XUEXI_SERVER = process.env.VUE_APP_XUEXI_SERVER; // 在线学习域名
var APPS_SERVER = process.env.VUE_APP_APPS_SERVER; // 信息服务中心域名

var SXYXLX_CODE = 100046 // 如果是100046并且是学生登录,不进入工作台,跳转到研学旅行页面
var YNJD_CODE = 100048 // 云南机电职业技术学院-登录页背景图定制
var YXLX_CODE = 100041 // 如果是100041并且是学生登录,不进入工作台,跳转到研学旅行页面
// if (process.env.NODE_ENV == 'development') {
//   HOST = '';
//   JIAOYU_CN = process.env.JIAOYU_CN
//   JIAOYU_SCHOOL = process.env.JIAOYU_SCHOOL
//   XUEXI_SERVER = 
//   APPS_SERVER = 'http://apps.66.dev.eol.com.cn'
// } else {
//   // 第二层判断打包后是测试环境还是正式环境 
//   if (process.env.VUE_APP_FLAG === 'pro') {
//     // 正式环境 
//     HOST = 'https://apps-api.jiaoyu.cn';
//     JIAOYU_CN = 'https://www.jiaoyu.cn'
//     JIAOYU_SCHOOL = 'https://yxt.jiaoyu.cn'
//     XUEXI_SERVER = 'https://xuexi.jiaoyu.cn'
//     APPS_SERVER = 'https://apps.jiaoyu.cn'
//   } else {
//     // 测试环境 
//     HOST = 'http://apps-api.66.dev.eol.com.cn';
//     JIAOYU_CN = 'http://www.66.dev.eol.com.cn'
//     JIAOYU_SCHOOL = 'http://yxt-student.43.dev.eol.com.cn'
//     XUEXI_SERVER = 'http://xuexi.66.dev.eol.com.cn'
//     APPS_SERVER = 'http://apps.66.dev.eol.com.cn'
//   }
// }

// console.log("host", HOST);
// console.log('当前环境', process.env.NODE_ENV);
// console.log("process.env.VUE_APP_FLAG", process.env.VUE_APP_FLAG);

//请求发送拦截
axios.interceptors.request.use((request) => {
  return request;
}, function (error) {
  return Promise.reject(error);
});

function handleFunc (res, params) {
  let codeStr = res.code + ''
  let code = localStorage.getItem("schoolCode")
  let path = window.location.href;
  if (codeStr == '2001') {
    if (params && !params.loginLink && path.indexOf('/login') == -1 && code) {
      router.push({
        path: `/${code}/login`,
      })
    }
  } else {
    // Message({
    //   showClose: true,
    //   message: res.message,
    //   type: 'error'
    // });
  }
}

const request = (method, url, params) => {
  if (method == 'post') {
    return axios.post(url, params).then((res) => res.data).then((res) => {
      handleFunc(res, params);
      return res
    })
  } else if (method == 'get') {
    return axios.get(url, { params }).then((res) => res.data).then((res) => {
      handleFunc(res, params);
      return res
    })
  }
}

export default {
  HOST: HOST,
  JIAOYU_CN: JIAOYU_CN,
  JIAOYU_SCHOOL: JIAOYU_SCHOOL,
  XUEXI_SERVER: XUEXI_SERVER,
  APPS_SERVER: APPS_SERVER,
  SXYXLX_CODE,
  YNJD_CODE,
  YXLX_CODE,
  getImageCaptcha (params) {
    return request('get', HOST + '/api/captcha', params)
  },
  getSmsCaptcha (params) {
    return request('get', HOST + '/api/sms/send', params)
  },
  appsLogin (params) {
    return request('post', HOST + '/api/login', params)
  },
  appsLogout (params) {
    return request('post', HOST + '/api/logout', params)
  },
  getSchoolInfo (params) {
    return request('get', HOST + '/api/login/info', params)
  },
  getUserInfo (params) {
    return request('get', HOST + '/api/user/info', params)
  },
  updateUserInfo (params) {
    return request('post', HOST + '/api/user/update', params)
  },
  postEditPassword (params) {
    return request('post', HOST + '/api/user/password', params)
  },
  postResetPassword (params) {
    return request('post', HOST + '/api/reset', params)
  },
  getSchoolAppList (params) {
    return request('get', HOST + '/api/school/app', params)
  },
  updateUserPhone (params) {
    return request('post', HOST + '/api/user/phone', params)
  },
  getManageUserList (params) {
    let url = '/api/manage/user'
    if (params.id) {
      url = '/api/manage/user/' + params.id
    }
    return request('get', HOST + url, params.data)
  },
  updateManageUser (params) {
    return request('post', HOST + '/api/manage/user/' + params.id, params.data)
  },
  updateUserStatus (params) {
    return request('post', HOST + '/api/manage/user/status', params)
  },
  resetUserPassword (params) {
    return request('post', HOST + '/api/manage/user/password', params)
  },
  getUserAppList (params) {
    return request('get', HOST + '/api/school/app/use', params)
  },
  getUserLogList (params) {
    return request('get', HOST + '/api/log/action', params)
  },
  wechatBindAccount (params) {
    return request('post', HOST + '/api/weixin/bind', params)
  },
  getWechatUnbind (params) {
    return request('post', HOST + '/api/user/unbind', params)
  },
  schoolLoginRedirect (params) {
    return request('get', HOST + '/api/redirect', params)
  },

}