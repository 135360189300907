<template>
<div class="right-form-box"> 
  <div class="resetPass-box">
    <div class="come-back" @click="backLogin()"></div>
    <div class="login-ways-box">
      <div class="ways-option active">设置新密码</div>
    </div>
    <!-- 账号登录 -->
    <div class="pane-form">
      <el-form ref="newForm" :model="form" :rules="formRules" label-width="90px">
        <el-form-item prop="password" :error="formErrors.password">
          <label slot="label">设置新密码</label>
          <el-input @paste.native.capture.prevent="handleFalse" v-model="form.password" type="password" autocomplete="off" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item prop="password_confirmation" :error="formErrors.password_confirmation">
          <label slot="label">确认新密码</label>
          <el-input @paste.native.capture.prevent="handleFalse" v-model="form.password_confirmation" type="password" autocomplete="off" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="login-btn" @click="submitForm()">确认</div>
    </div>
  </div>
</div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
// import LoginBanner from "@/components/LoginBanner.vue";
import { mapActions } from "vuex"

export default {
  name: 'ResetPassword',
  // components: {Footer, LoginBanner},
  data() {
    let validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入新密码'));
			} else {
				const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
				if(!reg.test(value)){
					callback(new Error('密码为[8-16]位字母大小写及数字组合'));
				} else {
					callback();
				}
			}
		};
		let validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入新密码'));
			} else if (value !== this.form.password) {
				callback(new Error("两次输入的密码不相同"));
			} else {
				callback();
			}
		};
    return {
      shoolCode: localStorage.getItem('schoolCode'),
      form: {
        pwd_force: 1,
        password: '',
        password_confirmation: '',
        _method: 'put',
      },
      formRules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        password_confirmation: [{ validator: validatePass2, trigger: "blur" }],
      },
      formErrors: {
        password: '',
        password_confirmation: '',
      }
    };
  },
  mounted() {
    let that = this
    if(that.shoolCode){
      that.getLoginSchoolInfo({school_code: that.shoolCode})
    }
  },
  methods: {
    ...mapActions(["requestEditPassword", 'getLoginSchoolInfo']),
    handleFalse() {
      // 终止
      return false;
    },
    backLogin(){
      this.$router.go(-1);
    },
     // 提交表单事件
    submitForm() {
      let that = this;
      
      that.$refs['newForm'].validate((valid) => {
        if (valid) {
          that.requestEditPassword({...that.form}).then((res) => {
            if(res.code == '0000'){
              that.$successMsg(res.message ? res.message : "修改成功");
              that.$router.push({path: `/${that.shoolCode}/worker`})
            } else {
              that.$errorMsg(res.message ? res.message : "修改失败")
            }
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.resetPass-box {
  position: relative;
}
.come-back {
  width: 8px;
  height: 16px;
  background: url("../../assets/imgs/login/back.png") no-repeat center;
  background-size: 100% auto;
  position: absolute;
  top: 40px;
  left: -20px;
  cursor: pointer;
}
.login-ways-box {
  width: 100%;
  text-align: center;
  padding: 90px 0 40px;
  .ways-option {
    font-size: 20px;
    font-weight: 400;
    color: #757575;
    padding: 0 0 10px;
    display: inline-block;
    cursor: pointer;
    &.active {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      position: relative;
      border-bottom: 3px solid #125CFB;
    }
  }
}
.forget-box {
  font-size: 14px;
  color: #125CFB;
  text-decoration: none;
  text-align: left;
  display: block;
  margin: 6px 0 28px;
}
.login-btn {
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #125CFD;
  border-radius: 3px;
  font-size: 16px;
  margin-top: 70px;
  letter-spacing: 20px;
  text-indent: 20px;
  color: #FFFFFF;
  cursor: pointer;
  &:hover {
    background: #094EE5;
  }
  &.paddingTop {
    margin-top: 70px;
  }
}

// 重写el-form样式
.el-form-item {
  background: #FFFFFF;
  border: 1px solid #F1F2F7;
  /deep/ .el-form-item__label {
    color: #333333;
    font-size: 14px;
    padding: 0 5px 0 0;
  }
  
  /deep/ .el-input__inner {
    border: none !important;
  }
  /deep/ .el-form-item__error {
    padding-left: 0;
    margin-left: -75px;
  }
}

/deep/ .el-carousel__indicators--horizontal {
  bottom: 40px;
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 0;
}
/deep/ .el-carousel__indicator.is-active button {
  opacity: 1;
}
/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  opacity: 0.5;
  border-radius: 50%;
  margin-right: 10px;
}

</style>