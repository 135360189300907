<template>
  <div>
    <vue-base-dialog :dialogConfig="dialogConfig" class="selfCreateDialog" ref="childDialog" @close="parentModalFunc">
      <div class="dialog-content">
        <div class="top-tips">
          绑定完成后，您可以使用该手机号码登录账号、找回密码
        </div>
        <!-- 绑定新手机号 -->
        <div class="center-form-box">
          <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="88px">
            <el-form-item prop="mobile" :error="mobileFormErrors.mobile">
              <label slot="label">手&nbsp;机&nbsp;号&nbsp;码</label>
              <el-input v-model="mobileForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="captcha" :error="mobileFormErrors.captcha" class="captcha-lines">
              <label slot="label">图形验证码</label>
              <el-input v-model="mobileForm.captcha" placeholder="请输入验证码"></el-input>
              <div class="captcha-box" @click="methodImageCaptcha()">
                <div class="captcha-img"><img :src="imageCode.img" /></div>
              </div>
            </el-form-item>
            <el-form-item prop="code" :error="mobileFormErrors.code" class="captcha-lines" label="短信验证码">
              <el-input v-model="mobileForm.code" placeholder="请输入短信验证码"></el-input>
              <div class="captcha-box" @click="clickloginSms()">
                <span :class="loginSmsClass">{{ loginSmsText }}</span>
              </div>
            </el-form-item>
          </el-form>
          <div class="bottom-line-btn">
            <div class="cancel-btn" @click="cancelModal">取消</div>
            <div class="right-btn" @click="submitForm()">确定</div>
          </div>
        </div>
      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
import { mapActions } from "vuex";

export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
  },
  components: {
    VueBaseDialog,
  },
  data () {
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[0-9]{10}$/
        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      imageCode: {
        img: "",
        key: "",
      },
      mobileForm: {
        mobile: "",
        code: "",
        captcha: '',
      },
      mobileRules: {
        mobile: [{ validator: validatePhone, trigger: "blur" }],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入验证码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入短信验证码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      mobileFormErrors: {
        captcha: "",
        mobile: "",
        code: "",
      },
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "captcha-txt", // captcha-time
    };
  },
  mounted () {
    this.methodImageCaptcha();
  },
  methods: {
    ...mapActions(["getImageCaptcha", "requestUpdateUserPhone", "getSmsCode"]),
    parentModalFunc () { },
    cancelModal () {
      this.$emit("cancleDialog");
      this.$refs['mobileForm'].resetFields();
    },
    // 请求图片验证码
    methodImageCaptcha () {
      let that = this;
      that.getImageCaptcha().then((res) => {
        if (res.code == "0000") {
          that.imageCode = res.data;
        }
      });
    },
    // 提交表单事件
    submitForm () {
      let that = this;
      that.$refs["mobileForm"].validate((valid) => {
        if (valid) {
          let param = {
            _method: "put",
            act: "bind", // 类型 check校验 bind绑定
            mobile: that.mobileForm.mobile,
            code: that.mobileForm.code,
          }
          that.requestUpdateUserPhone(param).then(res => {
            if (res.code == '0000') {
              that.$successMsg(res.message ? res.message : "操作成功")
              that.cancelModal()
              that.$emit('refreshData')
            } else {
              that.methodImageCaptcha()
              if (res.errors) {
                that.mobileFormErrors = { ...res.errors }
              } else {
                that.$errorMsg(res.message ? res.message : "操作失败")
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms () {
      let that = this;
      if (that.loginSmsClass === "captcha-txt") {
        let params = {};
        if (that.mobileForm.mobile) {
          const reg = /^1[0-9]{10}$/
          if (!reg.test(that.mobileForm.mobile)) {
            that.$set(this.mobileFormErrors, "mobile", "请输入正确的手机号");
            return false;
          } else {
            params.mobile = that.mobileForm.mobile;
          }
        } else {
          this.$set(this.mobileFormErrors, "mobile", "请输入手机号");
          return false;
        }
        if (that.mobileForm.captcha) {
          params.captcha = that.mobileForm.captcha;
        } else {
          that.$set(this.mobileFormErrors, "captcha", "请输入验证码");
          return false;
        }
        params.key = that.imageCode.key;
        that.getSmsCode(params).then((res) => {
          if (res.code === "0000") {
            that.smsCountDown();
            that.$successMsg(res.message ? res.message : "短信验证码发送成功");
          } else {
            if (res.errors) {
              that.mobileFormErrors = { ...res.errors };
              that.methodImageCaptcha();
            } else {
              that.$errorMsg(res.message ? res.message : "短信验证码发送失败");
            }
          }
        });
      }
    },
    // 验证码倒计时
    smsCountDown () {
      let that = this;
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "captcha-time";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "captcha-txt";
          clearInterval(that.interval1);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 60px;
}
.top-tips {
  width: 400px;
  height: 34px;
  background: #fffcf8;
  font-size: 12px;
  line-height: 34px;
  color: #ef7d47;
  margin-bottom: 30px;
}
.center-form-box {
  padding: 0 0 20px;
  // 重写el-form样式
  .el-form-item {
    background: #ffffff;
    border: 1px solid #f1f2f7;
    /deep/ .el-form-item__label {
      color: #333333;
      font-size: 14px;
      padding: 0 5px 0 0;
    }

    /deep/ .el-input__inner {
      border: none !important;
    }
    /deep/ .el-form-item__error {
      padding-left: 15px;
    }
    &.captcha-lines {
      /deep/ .el-input {
        width: calc(100% - 90px);
      }
      .captcha-box {
        width: 80px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        .captcha-img {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
          }
        }
        .captcha-txt {
          color: #125cfd;
          cursor: pointer;
          display: inline-block;
        }
        .captcha-time {
          color: #b6b8bd;
          display: inline-block;
        }
      }
    }
  }
  .bottom-line-btn {
    width: 100%;
    text-align: center;
    padding: 20px 0 0;
    div {
      width: 102px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
    }
    .right-btn {
      background: #f2f6ff;
      border: 1px solid #2462f5;
      color: #125cfd;
      &:hover {
        background: #ffffff;
      }
    }
    .cancel-btn {
      color: #333333;
      background: #f8f8f8;
      border: 1px solid #dbdbdb;
      margin-right: 50px;
      &:hover {
        background: #ffffff;
      }
    }
  }
}
// 适配h5
@media screen and (max-width: 600px) {
  .dialog-content {
    padding: 20px 0 !important;
  }
  .top-tips {
    width: 90% !important;
    margin: 0 auto 30px !important;
  }
  .center-form-box {
    width: 90% !important;
    margin: 0 auto !important;
    box-sizing: border-box !important;
  }
}
</style>