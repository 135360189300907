<template>
  <div class="manage-table-list" ref="middleBox">
    <div class="manage-table-inner" id="multipleTable">
      <el-table :data="tableData"  stripe size="mini" :cell-style='cellStyle' :header-cell-style='cellStyle' style="width: 1048px;">
        <el-table-column prop="id" label="操作ID" width="70">
        </el-table-column>
        <el-table-column prop="created_name" label="操作人" width="160">
        </el-table-column>
        <el-table-column prop="module_name" label="菜单" width="120">
        </el-table-column>
        <el-table-column prop="second_module_name" label="子菜单" width="140">
        </el-table-column>
        <el-table-column prop="created_at" label="操作时间" width="220"> </el-table-column>
        <el-table-column prop="content" label="操作内容"> 
           <template slot-scope="scope"> 
             <div style="line-height: 34px;text-align: left;">{{scope.row.content}}</div>
           </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="public-page-box"
      background
      layout="total, prev, pager, next"
      :current-page="page"
      :page-size="size"
      :total="total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OperationLog",
  components: {
  },
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      isFooterFixed: false,
    };
  },
  mounted(){
    this.$openLoading()
    this.methodLogList();

    let that = this
    this.$nextTick(() => {
      that.setFooterHeight()
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
  },
  methods: {
    ...mapActions(["requestManageLogList"]),
    setFooterHeight(){
      // let middleHeight = this.$refs.middleBox.offsetHeight; 
      let middleHeight = document.getElementById("multipleTable").offsetHeight; 
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if((hth - 160) > (middleHeight + 307)){
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
      this.$emit("onTableHeight", this.isFooterFixed)
    },
    cellStyle(row){
      let styleStr = "text-align:center"
      if(row.column.label == '操作内容'){
        styleStr = "text-align:left"    
      }
      return styleStr
    },
    methodLogList (page){
      let that = this
      this.$openLoading()
      that.requestManageLogList({
        page: page,
        size: that.size,
      }).then(res => {
        that.$closeLoading()
        if(res.code == '0000'){
          that.total = res.data.total ?res.data.total :0
          that.tableData = res.data.items
          setTimeout(function(){
            that.setFooterHeight()
          },1500)
        }
      })
    },
    handleCurrentChange(currentP){
      this.methodLogList(currentP)
    }
  },
};
</script>

<style lang="less" scoped>
.manage-table-list {
  flex: 1;
  background: #ffffff;
  border: 1px solid #e7e9f8;
  border-left: none;
  padding: 0 0 30px;
  .manage-table-inner {
    padding: 20px 25px 30px;
    box-sizing: border-box;
    width: 100%;
    /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background: #fafefb;
    }
    /deep/ .el-table--striped.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
      background: #f5f7fd !important;
    }
  }
}
</style>