<template>
  <div class="manage-table-list" ref="middleBox">
    <div class="manage-table-inner" id="multipleTable">
      <el-table :data="tableData"  stripe size="mini" :cell-style='cellStyle' :header-cell-style='cellStyle' style="width: 1048px;">
        <el-table-column prop="id" label="应用ID" width="80">
        </el-table-column>
        <el-table-column prop="name" label="应用名称" width="200">
        </el-table-column>
        
        <el-table-column label="有效时间" width="200">
           <template slot-scope="scope">{{scope.row.start_date}}~{{scope.row.end_date}}</template>
        </el-table-column>
        <el-table-column prop="total_num" label="历史总用户" width="120"> 
          <template slot-scope="scope">{{scope.row.total_num ? scope.row.total_num : 0}}人</template>
        </el-table-column>
        <el-table-column label="有效用户" width="120">
          <template slot-scope="scope">
            <el-button v-if="scope.row.use_num > 0" @click="showCurrentNum(scope.row)" type="text" size="small">{{scope.row.use_num}}人</el-button>
            <span v-else style="line-height: 34px;">{{scope.row.use_num ? scope.row.use_num : 0}}人</span>
          </template>
        </el-table-column>
        <el-table-column prop="intro" label="描述" >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Application",
  components: {
  },
  data() {
    return {
      tableData: [],
      isFooterFixed: false,
      shoolCode: localStorage.getItem('schoolCode'),
    };
  },
  mounted(){
    let that = this
    this.$nextTick(() => {
      that.setFooterHeight()
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
    this.$openLoading()
    this.methodAppList()
  },
  methods: {
    ...mapActions(["requestManageAppList"]),
    setFooterHeight(){
      // let middleHeight = this.$refs.middleBox.offsetHeight; 
      let middleHeight = document.getElementById("multipleTable").offsetHeight; 
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if((hth - 160) > (middleHeight + 307)){
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
      this.$emit("onTableHeight", this.isFooterFixed)
    },
    methodAppList (){
      let that = this
      that.$openLoading()
      that.requestManageAppList().then(res => {
        that.$closeLoading()
        if(res.code == '0000'){
          that.tableData = res.data
          setTimeout(function(){
            that.setFooterHeight()
          },1500)
        }
      })
    },
    cellStyle(row){
      let styleStr = "text-align:center"
      if(row.column.label == '描述' || row.column.label == '应用名称'){
        styleStr = "text-align:left"    
      }
      return styleStr
    },
    // 点击去查看当前应用的所有用户
    showCurrentNum(row){
      this.$router.push({
        path: `/${this.shoolCode}/userManage/userList`,
        query: {
          id: row.id,
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.manage-table-list {
  flex: 1;
  background: #ffffff;
  border: 1px solid #e7e9f8;
  border-left: none;
  .manage-table-inner {
    padding: 20px 25px 30px;
    box-sizing: border-box;
    width: 100%;
    /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background: #fafefb;
    }
    /deep/ .el-table--striped.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
      background: #f5f7fd !important;
    }
  }
}
</style>