<template>
  <div class="manage-table-list" ref="middleBox">
    <div class="manage-table-inner">
      <div class="manage-search-box">
        <el-input placeholder="搜索内容" v-model="searchTxt" class="input-with-select" size="small">
          <el-select v-model="searchSelect" slot="prepend" placeholder="请选择" size="small">
            <el-option label="用户名称" value="1"></el-option>
            <el-option label="手机号" value="2"></el-option>
          </el-select>
        </el-input>
        <el-select class="select-inline" v-model="appName" multiple collapse-tags placeholder="应用名称" size="small">
          <template  v-for="item in manageAllApp">
            <el-option
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
          </template>
        </el-select>
        <el-select class="status-inline" v-model="status" clearable placeholder="状态" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
        <el-button type="primary" size="mini" @click="searchUserList(1)">搜索</el-button>
        <el-button class="right-float" icon="el-icon-video-pause" @click="changeUserStatus(2)" type="danger" size="mini">禁用</el-button>
        <el-button class="right-float" icon="el-icon-video-play"  @click="changeUserStatus(1)" type="success" size="mini">启用</el-button>
      </div>
      <div id="multipleTable">
        <el-table  ref="multipleTable" :data="tableData" stripe size="mini" tooltip-effect='dark myTooltips' :cell-style='cellStyle' @selection-change="handleSelectionChange" :header-cell-style='cellStyle' style="width: 1048px;">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="用户ID" width="80"></el-table-column>
          <el-table-column prop="name" label="用户名称" width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="username" label="用户账号" width="150"></el-table-column>
          <el-table-column prop="mobile" label="手机号" width="140"></el-table-column>
          <el-table-column prop="remark" label="备注" width="160"  show-overflow-tooltip></el-table-column>
          <el-table-column prop="app_names" label="开通的应用" width="200"> </el-table-column>
          <el-table-column prop="status" label="状态" width="100"> 
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1" class="status-icon on">启用</div>
              <div v-if="scope.row.status == 2" class="status-icon off">禁用</div>
            </template>
          </el-table-column>
          <el-table-column prop="login_num" label="登录次数" width="80"> </el-table-column>
          <el-table-column prop="updated_at" label="更新时间" width="160"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="140">
            <template slot-scope="scope">
              <el-button type="text" @click="editModal(scope.row)" size="small">修改</el-button>
              <el-button type="text" size="small" @click="resetPassword(scope.row)">重置密码</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      class="public-page-box"
      background
      layout="total, prev, pager, next"
      :current-page="page"
      :page-size="size"
      :total="total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <component 
    :is="dialogConfig.name" 
    :dialogConfig="dialogConfig" 
    @cancleDialog="cancleDialog(arguments)"
    @refreshData="refreshInfo"
    ></component>
    <!--  -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "userList",
  components: {
    editUserModal:() => import("./editUserModal.vue"),
    resetPassModal:() => import("./resetPassModal.vue"),
  },
  data() {
    return {
      multipleId: [],
      searchTxt: '',
      searchSelect: '1', // 1用户名称2手机号
      appName: '',
      status: '',
      dialogConfig: {
				name: '',
				title: '',
				data:null,
				dialogVisible: true,
			},
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      appId: '',
      isFooterFixed: false,
    };
  },
  computed: {
    ...mapState({ 
      manageAllApp: (state) => state.manage.manageAllApp,
    }),
  },
  async mounted(){
    let that = this
    
    this.$openLoading()
    await this.requestManageAllApp().then(res => {
      if(res.code == '0000'){
        if(this.$route.query.id){
          this.appName = [Number(this.$route.query.id)]
        }
      }
    });
    await this.searchUserList(this.page)

    this.$nextTick(() => {
      // setTimeout(function(){
      //   that.setFooterHeight()
      // },1500)
      
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
  },
  methods: {
    ...mapActions(["requestManageAllApp", 'requestManageUserList', 'requestUserResetPass', 'requestUserStatus']),
    setFooterHeight(){
      // let middleHeight = this.$refs.middleBox.offsetHeight; 
      let middleHeight = document.getElementById("multipleTable").offsetHeight; 
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if((hth - 160) > (middleHeight + 307)){
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
      this.$emit("onTableHeight", this.isFooterFixed)
    },
    // 点击修改事件
    editModal (row){
      this.dialogConfig.name  = 'editUserModal';
			this.dialogConfig.title = "修改用户";
      this.dialogConfig.data = {...row}
    },
    // 刷新页面
    refreshInfo(){
			this.searchUserList(this.page)
		},
    //关闭弹框
		cancleDialog(){
			this.dialogConfig.name = null;
		},
    cellStyle(){
      return "text-align:center"
    },
    // 重置密码点击事件
    resetPassword (row){
      let that = this
      that.$confirm(`<span style="color:#333333">您确定要重置用户<span style="font-weight:bold;color:#333333">（${row.name}）</span>的密码吗</span>？`, '重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        iconClass: 'noneConfirmIcon',
        cancelButtonClass: 'cancelConfirmBtn',
        confirmButtonClass: 'rightConfirmBtn',
        dangerouslyUseHTMLString: true
      }).then(() => {
        that.$openLoading()
        that.methodResetUserPass({data: {id: row.id, _method: 'put'}, name: row.name})
      }).catch(() => { })
    },
    // 重置密码请求
    methodResetUserPass(param){
      let that = this
      that.requestUserResetPass(param.data).then(res => {
        that.$closeLoading()
        if(res.code == '0000'){
          that.dialogConfig.name  = 'resetPassModal';
          that.dialogConfig.title = "重置密码";
          that.dialogConfig.data = {password: res.data.password, name: param.name}
        } else {
          that.$errorMsg(res.message ? res.message : "操作失败")
        }
      })
    },
    // 获取用户列表请求
    methodManageUserList (params){
      let that = this
      that.$openLoading()
      that.requestManageUserList({data:params}).then(res => {
        that.$closeLoading()
        if(res.code == '0000'){
          that.total = res.data.total ?res.data.total :0
          that.tableData = res.data.items
          setTimeout(function(){
            that.setFooterHeight()
          },1000)
        }
      })
    },
    // 分页点击事件
    handleCurrentChange(currentP){
      this.page = currentP
      this.searchUserList(currentP)
    },
    // 搜索点击事件
    searchUserList(page){
      let params = {
        page: page,
        size: this.size,
      }
      if(this.searchTxt && this.searchTxt.length > 0){
        params.type= this.searchSelect
        params.q = this.searchTxt
      } 
      if(this.appName && this.appName.length > 0){
        params.app_id = this.appName.join(",")
      } 
      if(this.status){
        params.status = this.status
      } 
      this.methodManageUserList(params)
    },
    // 多选点击事件
    handleSelectionChange(val){
      let arr = [];
      val.forEach(element => {
        arr.push(element.id)
      });
      this.multipleId = arr
    },
    // 启用、禁用点击事件
    changeUserStatus(val){
      let that = this
      if(this.multipleId && this.multipleId.length > 0){
        let mulstr = this.multipleId.join(",");
        that.$openLoading()
        this.requestUserStatus({
          id: mulstr,
          status: val,
          _method: 'put',
        }).then(res => {
          that.$closeLoading()
          if(res.code == '0000'){
            that.$refs.multipleTable.clearSelection();
            that.$successMsg(res.message ? res.message : "操作成功");
            that.refreshInfo()
          } else {
            that.$errorMsg(res.message ? res.message : "操作失败")
          }
        })
      } else {
        that.$errorMsg("请至少选择一个用户")
      }
    }
  },
};
</script>

<style lang="less">
.el-tooltip__popper {
  max-width:60%; 
}
.el-tooltip__popper.is-dark{ 
  background:#4671e1 !important;
  color: #fff !important;
  line-height: 22px;
}
</style>
<style lang="less" scoped>
.manage-table-list {
  flex: 1;
  background: #ffffff;
  border: 1px solid #e7e9f8;
  border-left: none;
  padding: 0 0 30px;
  .manage-table-inner {
    padding: 20px 25px 30px;
    box-sizing: border-box;
    width: 100%;
    .status-icon {
      font-size: 14px;
      color: #2FC329;
      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #81DD7E;
        border-radius: 50%;
        margin-right: 7px;
        vertical-align: 2px;
      }
      &.off {
        color: #EF453C;
      }
      &.off::before {
        background: #FAB0AC;
      }
    }
    /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background: #fafefb;
    }
    /deep/ .el-table--striped.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
      background: #f5f7fd !important;
    }
  }
}
/deep/ .el-input__inner {
  border-color: #DFE3E4;
}
.manage-search-box {
  padding: 10px 0 15px;
  text-align: left;
  border-bottom: 1px solid #EFEFF4;
  .input-inline {
    width: 180px;
    display: inline-block;
    margin-right: 15px;
  }
  .select-inline {
    width: 240px;
    display: inline-block;
    margin-right: 15px;
  }
  .status-inline {
    width: 100px;
    display: inline-block;
    margin-right: 15px;
  }
  .right-float {
    float: right;
  }
  .input-with-select {
    width: 330px;
    margin-right: 15px;
  }
  
  /deep/ .input-with-select .el-select .el-input {
    width: 110px;
  }
  /deep/ .input-with-select .el-input-group__prepend {
    background-color: #ffffff;
  }
}
</style>