<template>
  <div>
    <vue-base-dialog :dialogConfig="dialogConfig" class="selfCreateDialog" ref="childDialog" @close="parentModalFunc">
      <div class="dialog-content">
        <!-- 绑定新手机号 -->
        <div class="center-form-box">
          <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="90px" size="small">
            <el-form-item prop="name" :error="mobileFormErrors.name">
              <label slot="label">用户名称:</label>
              <el-input v-model="mobileForm.name" placeholder="请输入用户名称"></el-input>
            </el-form-item>
            <el-form-item class="disabled-lines">
              <label slot="label">用户账号:</label>
              <div class="disabled-name">{{dialogConfig.data.username}}</div>
            </el-form-item>
            <el-form-item prop="mobile" :error="mobileFormErrors.mobile">
              <label slot="label">手&nbsp;&nbsp;机&nbsp;&nbsp;号:</label>
              <el-input v-model="mobileForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="email" :error="mobileFormErrors.email">
              <label slot="label">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱:</label>
              <el-input v-model="mobileForm.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item prop="remark" :error="mobileFormErrors.remark">
              <label slot="label">备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:</label>
              <el-input v-model="mobileForm.remark" type="textarea" placeholder="请输入备注，最多20字符"></el-input>
            </el-form-item>
          </el-form>
          <div class="bottom-line-btn">
            <div class="cancel-btn" @click="cancelModal">取消</div>
            <div class="right-btn" @click="editMethod()">确定</div>
          </div>
        </div>
      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
import { mapActions } from "vuex";

export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
    form: {}
  },
  components: {
    VueBaseDialog,
  },
  data () {
    let validatePhone = (rule, value, callback) => {
      if (value) {
        const reg = /^1[0-9]{10}$/
        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let validateRemark = (rule, value, callback) => {
      if (value.length > 20) {
        callback(new Error("最多20字符"));
      } else {
        callback();
      }
    };
    let validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名称"));
      } else {
        if (value.length > 50) {
          callback(new Error("用户名称不能超过50个字符"));
        } else {
          callback();
        }
      }
    };
    return {
      details: null,
      mobileForm: {
        mobile: "",
        name: '',
        email: '',
        remark: '',
        _method: 'put'
      },
      mobileRules: {
        mobile: [
          { validator: validatePhone, trigger: "blur" }
        ],
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { alidator: validateName, trigger: "blur" }
        ],
        email: [
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        remark: [{ validator: validateRemark, trigger: "blur" }]
      },
      mobileFormErrors: {
        mobile: "",
        name: '',
        email: '',
        remark: '',
      },
    };
  },
  mounted () {
    this.getUserDetails()
  },
  methods: {
    ...mapActions(['requestManageUserList', 'editManageUser']),
    parentModalFunc () { },
    cancelModal () {
      this.$emit('cancleDialog');
      this.$refs['mobileForm'].resetFields();
    },
    getUserDetails () {
      let that = this
      that.requestManageUserList({ id: that.dialogConfig.data.id }).then(res => {
        if (res.code == '0000') {
          if (res.data) {
            that.details = res.data
            that.$set(that.mobileForm, 'mobile', res.data.mobile)
            that.$set(that.mobileForm, 'name', res.data.name)
            that.$set(that.mobileForm, 'email', res.data.email)
            that.$set(that.mobileForm, 'remark', res.data.remark)
          }
        }
      })
    },
    editMethod () {
      let that = this;
      that.$refs['mobileForm'].validate((valid) => {
        if (valid) {
          that.editManageUser({
            id: that.dialogConfig.data.id,
            data: { ...that.mobileForm }
          }).then(res => {
            if (res.code == '0000') {
              that.$successMsg(res.message ? res.message : "操作成功");
              that.cancelModal();
              that.$emit('refreshData')
            } else {
              if (res.errors) {
                that.mobileFormErrors = { ...res.errors }
              } else {
                that.$errorMsg(res.message ? res.message : "操作失败")
              }
            }
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 60px;
}
.top-tips {
  width: 400px;
  height: 34px;
  background: #fffcf8;
  font-size: 12px;
  line-height: 34px;
  color: #ef7d47;
  margin-bottom: 30px;
}
.center-form-box {
  width: 400px;
  padding: 0 0 20px;
  // 重写el-form样式
  .el-form-item {
    background: #ffffff;
    // border: 1px solid #f1f2f7;
    /deep/ .el-form-item__label {
      color: #333333;
      font-size: 14px;
      padding: 0 15px 0 0;
    }

    /deep/ .el-input__inner {
      // border: none !important;
      border: 1px solid #dee0e4;
    }
    /deep/ .el-form-item__error {
      padding-left: 15px;
    }
  }
  /deep/
    .el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label:before {
    margin-right: 6px !important;
  }
  .disabled-lines {
    border: none !important;
    .disabled-name {
      padding: 0 15px;
      text-align: left;
      color: #333333;
    }
  }
  .bottom-line-btn {
    width: 100%;
    text-align: center;
    padding: 40px 0 20px;
    div {
      width: 102px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
    }
    .right-btn {
      background: #f2f6ff;
      border: 1px solid #2462f5;
      color: #125cfd;
      &:hover {
        background: #ffffff;
      }
    }
    .cancel-btn {
      color: #333333;
      background: #f8f8f8;
      border: 1px solid #dbdbdb;
      margin-right: 50px;
      &:hover {
        background: #ffffff;
      }
    }
  }
}
</style>