<template>
  <div class="content-box">
    <div class="content-inner">
      <div class="tips-box">
        <div class="left-imgs">
          <img src="@/assets/imgs/common/success.png" />
        </div>
        <div class="right-text">
          <div class="top-label">授权成功</div>
          <div v-if="loginUserInfo && loginUserInfo.id == 0" class="little-txt">您已登录成功，请绑定账号</div>
          <div v-else class="little-txt">{{showText}}</div>
        </div>
      </div>

      <div v-if="loginUserInfo && loginUserInfo.id == 0" class="bindAccount-box">
        <el-form
          ref="accountForm"
          :model="accountForm"
          :rules="accountRules"
          label-width="65px"
        >
          <el-form-item
            label=""
            prop="username"
            :error="accountFormErrors.username"
          >
            <label slot="label">账&nbsp;&nbsp;&nbsp;&nbsp;号</label>
            <el-input
              v-model="accountForm.username"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
					<el-form-item
          prop="password"
          :error="accountFormErrors.password"
        >
          <label slot="label">密&nbsp;&nbsp;&nbsp;&nbsp;码</label>
          <el-input
            type="password"
            v-model="accountForm.password"
            autocomplete="off"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
          <el-form-item
            class="captcha-lines"
            prop="captcha"
            :error="accountFormErrors.captcha"
            label="验证码"
          >
            <el-input
              placeholder="请输入验证码"
              v-model="accountForm.captcha"
            ></el-input>
            <div class="captcha-box" @click="methodImageCaptcha()">
              <div class="captcha-img"><img :src="imageCode.img" /></div>
            </div>
          </el-form-item>
        </el-form>
        <div class="login-btn" @click="submitForm('accountForm')">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/assets/js/services'
import { mapActions, mapState } from "vuex";
import { decode } from 'js-base64';

export default {
  name: "WechatSuccess",
  data() {
		let validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error("密码为[8-16]位字母大小写及数字组合"));
        } else {
          callback();
        }
      }
    };
    return {
			services,
      shoolCode: localStorage.getItem('schoolCode'),
			accountForm: {
        username: "",
        captcha: "",
				password: "",
      },
      accountRules: {
        username: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入账号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
				],
        password: [{ validator: validatePassword, trigger: "blur" }],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入验证码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      accountFormErrors: {
        username: "",
        captcha: "",
				password: "",
      },
			imageCode: {
        key: '',
        img: '',
      },
			showText: '',
		};
  },
	computed: {
    ...mapState({
      loginUserInfo: (state) => state.login.loginUserInfo,
    }),
  },
  created() {
		let backObj = null
		if(this.$route.query.data){
			backObj = JSON.parse(decode(this.$route.query.data)) 
			if(backObj.code == '0000' && backObj.data){
				this.showText = backObj.message
				if(backObj.data.token){
					this.methodLoginUserInfo()
				} else if (backObj.data.back_url){
					window.open(backObj.data.back_url, "_self")
				}
			}
		}
	},
	methods: {
    ...mapActions(["getImageCaptcha", 'getLoginUserInfo']),
		// 登录后获取用户信息
    methodLoginUserInfo(){
      let that = this
      that.getLoginUserInfo({is_append: 1}).then(res => {
        if(res.code == '0000' && res.data){
					if(res.data.id == 0) {
						that.methodImageCaptcha()
					} else {
						if(res.data.pwd_force == 1) {
							// pwd_force 1 强制修改密码 2否
							that.$router.push({path: `/${this.shoolCode}/newPassword`})
						} else {
							that.$router.push({path: `/${this.shoolCode}/worker`})
						}
					}
        }
      })
    },
		// 是否去强制修改密码
		methodPwdforce(){
			let that = this
			that.getLoginUserInfo({is_append: 1}).then(res => {
				if(res.code == '0000'){
					if(res.data && res.data.pwd_force == 1) {
						// pwd_force 1 强制修改密码 2否
						that.$router.push({path: `/${this.shoolCode}/newPassword`})
					} else {
						that.$router.push({path: `/${this.shoolCode}/worker`})
					}
				}
			})
		},
		// 请求图片验证码
    methodImageCaptcha (){
      let that = this;
      that.getImageCaptcha().then((res) => {
        if(res.code == '0000'){
          that.imageCode = res.data
        }
      });
    },
		// 提交表单事件
		submitForm(formname) {
			let that = this;
			let param = {
				key: that.imageCode.key,
				...that.accountForm,
			}
			let code = localStorage.getItem("schoolCode")
			if(code){
				param.school_code = code
			} else {
				return false
			}
			that.$refs[formname].validate((valid) => {
				if (valid) {
					services.wechatBindAccount(param).then(res => {
						if(res.code == '0000') {
							that.methodPwdforce()
						} else {
							that.methodImageCaptcha()
							if (res.errors) {
								that[formname + 'Errors'] = res.errors
							} else {
								that.$errorMsg(res.message ? res.message : "操作失败")
							}
						}
					})
				} else {
					return false;
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #fff;
}
.content-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tips-box {
  display: flex;
  align-items: center;
	justify-content: center;
}
.left-imgs {
  width: 120px;
  height: auto;
  margin-right: 20px;
}
.left-imgs img {
  width: 100%;
  height: auto;
}
.right-text {
  text-align: left;
}
.top-label {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 50px;
}
.little-txt {
  font-size: 14px;
  color: #8a8d94;
}
.bindAccount-box {
  width: 350px;
  margin: 0 auto;
  display: block;
  padding: 60px 0 0;
  .login-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #125cfd;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 60px;
    letter-spacing: 20px;
    text-indent: 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #094ee5;
    }
  }
  // 重写el-form样式
  .el-form-item {
    background: #ffffff;
    border: 1px solid #f1f2f7;
    /deep/ .el-form-item__label {
      color: #333333;
      font-size: 14px;
      padding: 0 5px 0 0;
    }

    /deep/ .el-input__inner {
      border: none !important;
    }
    /deep/ .el-form-item__error {
      padding-left: 15px;
    }

    &.captcha-lines {
      /deep/ .el-input {
        width: calc(100% - 90px);
      }
      .captcha-box {
        width: 80px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        .captcha-img {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
          }
        }
        .captcha-txt {
          color: #125cfd;
          cursor: pointer;
          display: inline-block;
        }
        .captcha-time {
          color: #b6b8bd;
          display: inline-block;
        }
      }
    }
  }
}
</style>