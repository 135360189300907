<template>
  <div :class="isFixed ? 'login-footer-box fixedBox' : 'login-footer-box'">
    <div class="glbbot">
      <a href="http://www.eol.cn/html/cer.net/icp.htm">京ICP证140769号</a> |
      <a href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action">
        京ICP备2022007846号-1 </a>|
      <a href="http://img.eol.cn/images/indexnew/www1024.jpg">京网文[2014]2106-306号</a> |
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236">京公网安备 11010802020236号</a>
      <br>
      版权所有 北京中教互联教育科技有限公司
      EOL Corporation<br>
      Mail to: <a href="mailto:wangjr@eol.cn">wangjr@eol.cn</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {

    };
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.login-footer-box {
    padding: 15px 0;
    overflow: hidden;
    position: static;
    color: #646f7f;
    font-size: 13px;
    background: #f4f7fc;
}
.login-footer-box.fixedBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.login-footer-box .glbbot {
    line-height: 20px;
    text-align: center;
}
.login-footer-box .glbbot a:link,
.login-footer-box .glbbot a:visited {
    color: #646f7f;
    text-decoration: none;
}

// @media only screen and (max-height: 780px) {
//   .login-footer-box {
//     position: static;
//   }
// }
</style>
