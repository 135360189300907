<template>
  <div>
    <vue-base-dialog
      :dialogConfig="dialogConfig"
      class="selfCreateDialog"
      ref="childDialog"
      @close="parentModalFunc"
    >
      <div class="dialog-content">
        <!-- 绑定新手机号 -->
        <div class="center-form-box">
          <el-form ref="accountForm" :model="accountForm" :rules="accountRules" label-width="95px" size="small">
            <el-form-item class="disabled-lines">
              <label slot="label">用户账号</label>
              <div class="disabled-name">{{dialogConfig.data.username}}</div>
            </el-form-item>
            <el-form-item prop="name" :error="accountFormErrors.name">
              <label slot="label">用户名称</label>
              <el-input v-model="accountForm.name" placeholder="请输入用户名称"></el-input>
            </el-form-item>
            <el-form-item prop="email" :error="accountFormErrors.email">
              <label slot="label">邮箱地址</label>
              <el-input v-model="accountForm.email" placeholder="请输入邮箱地址"></el-input>
            </el-form-item>
          </el-form>
          <div class="bottom-line-btn">
            <div class="cancel-btn" @click="cancelModal">取消</div>
            <div class="right-btn" @click="editMethod">确定</div>
          </div>
        </div>
      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
import { mapActions } from "vuex";

export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
    form: {}
  },
  components: {
    VueBaseDialog,
  },
  data() {
    return {
      accountForm: {
        name: "",
        email: "",
        _method: 'put',
      },
      accountRules: {
        name: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          // {
          //   validator: (rule, value, callback) => {
          //     if (value === "") {
          //       callback(new Error("请输入用户名称"));
          //     } else {
          //       callback();
          //     }
          //   },
          //   trigger: "blur",
          // },
        ],
        email: [ 
          // { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
      },
      accountFormErrors: {
        name: "",
        email: "",
      },
    };
  },
  mounted() {
    if(this.$parent.loginUserInfo){
      if(this.$parent.loginUserInfo.name){
        this.$set(this.accountForm, 'name', this.$parent.loginUserInfo.name)
      }
      if(this.$parent.loginUserInfo.email){
        this.$set(this.accountForm, 'email', this.$parent.loginUserInfo.email)
      }
    }
  },
  methods: {
   ...mapActions(["requestUpdateUserInfo"]),
    parentModalFunc() {},
    editMethod(){
      let that = this;
      that.$refs['accountForm'].validate((valid) => {
        if (valid) {
          that.requestUpdateUserInfo({...that.accountForm}).then(res => {
            if(res.code == '0000'){
              that.$successMsg(res.message ? res.message : "操作成功");
              that.cancelModal();
              that.$emit('refreshData')
            } else {
              if (res.errors) {
                that.accountFormErrors = {...res.errors}
              } else {
                that.$errorMsg(res.message ? res.message : "操作失败")
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    cancelModal(){
      this.$emit('cancleDialog');
      this.$refs['accountForm'].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 60px;
}
.top-tips {
  width: 400px;
  height: 34px;
  background: #FFFCF8;
  font-size: 12px;
  line-height: 34px;
  color: #EF7D47;
  margin-bottom: 30px;
}
.center-form-box {
  width: 400px;
  padding: 0 0 20px;
  // 重写el-form样式
  .el-form-item {
    background: #ffffff;
    // border: 1px solid #f1f2f7;
    /deep/ .el-form-item__label {
      color: #333333;
      font-size: 14px;
      padding: 0 15px 0 0;
    }

    /deep/ .el-input__inner {
      // border: none !important;
      border: 1px solid #DEE0E4;
    }
    /deep/ .el-form-item__error {
      padding-left: 15px;
    }
  }
  /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    margin-right: 6px !important;
  }
  .disabled-lines {
    border: none !important;
    .disabled-name {
      padding: 0 15px;
      text-align: left;
      color: #333333;
    }
  }
  .bottom-line-btn {
    width: 100%;
    text-align: center;
    padding: 40px 0 20px;
    div {
      width: 102px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
    }
    .right-btn {
      background: #F2F6FF;
      border: 1px solid #2462F5;
      color: #125cfd;
      &:hover {
        background: #FFFFFF;
      }
    }
    .cancel-btn {
      color: #333333;
      background: #F8F8F8;
      border: 1px solid #DBDBDB;
      margin-right: 50px;
      &:hover {
       background: #FFFFFF;
      }
    }
  }
 
}
// 适配h5
@media screen and (max-width: 600px) {
  .dialog-content {
    padding: 20px 30px 20px 20px !important;
  }
  .center-form-box {
    width: 75vw !important;
    margin: 0 auto !important;
    box-sizing: border-box !important;
    .bottom-line-btn {
      margin-left: 10px !important;
    }
  }
}
</style>