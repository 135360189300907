<template>
  <div class="left-swiper-box">
    <el-carousel v-if="schoolInfo && schoolInfo.images" arrow="never">
      <el-carousel-item v-for="(item,index) in schoolInfo.images" :key="index">
        <div class="swiper-items" @click="goToDetails(item)" :style="`background-image: url('${item.image_src}')`">
        <!-- <div class="swiper-items" @click="goToDetails(item)" :style="`background-image: url('${require('@/assets/imgs/login/login-banner.png')}')`"> -->
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import  '../assets/css/loginMobile.less'
export default {
  name: 'LoginBanner',
  data() {
    return {
    };
  },
  computed: {
    ...mapState({ schoolInfo: (state) => state.login.schoolInfo }),
  },
  mounted() {
   
  },
  methods: {
    ...mapActions(["getLoginSchoolInfo"]),
    goToDetails(item){
      if(item.jump_url){
        window.open(item.jump_url, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.left-swiper-box {
  width: 560px;
  height: 560px;
  display: inline-block;
  vertical-align: top;
  /deep/ .el-carousel__container {
    width: 560px;
    height: 560px;
    .swiper-items {
      width: 560px;
      height: 560px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

// 修改轮播分页样式
/deep/ .el-carousel__indicators--horizontal {
  bottom: 15px;
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 0;
}
/deep/ .el-carousel__indicator.is-active button {
  opacity: 1;
}
/deep/ .el-carousel__button {
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  opacity: 0.5;
  border-radius: 50%;
  margin-right: 8px;
}
</style>