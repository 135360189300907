import services from '@/assets/js/services'
import utils from '@/assets/js/utils'

export default {
  state: {
    imageCode: {
      key: '',
      img: '',
    },
    schoolInfo: null, // 登录页图片轮播
    loginUserInfo: {}, // 登录后用户信息
    
  },
  getters: {
  },
  actions: {
    // 获取图片验证码
    getImageCaptcha(context, payload) {
      return services.getImageCaptcha(payload).then(res => {
        // console.log(res);
        if (res.code == '0000') {
          context.commit('setImageCode', {
            'imageCode': res.data
          })
        }
        return res;
      })
    },
    // 获取手机短信验证码
    getSmsCode (context, payload) {
      return services.getSmsCaptcha(payload).then(res => {
        return res;
      })
    },
    // 登录
    requestLoginApps(context, payload) {
      return services.appsLogin(payload).then(res => {
        if (res.code == '0000') {
          localStorage.setItem("appsToken", res.data.token)
        }
        return res;
      })
    },
    // 获取学校登录页轮播图片
    getLoginSchoolInfo(context, payload) {
      return services.getSchoolInfo(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSchoolInfo', {
            'data': res.data ? res.data : []
          })
        }
        return res;
      })
    },
    // 获取用户信息
    getLoginUserInfo(context, payload) {
      return services.getUserInfo({...payload, is_school: 1}).then(res => {
        if (res.code == '0000') {
          if(res.data){
            utils.setUserInfo(res.data)
            context.commit('setLoginUserInfo', {
              'userInfo': res.data ? res.data : {}
            })
          }
        }
        return res;
      })
    },
    // 修改密码、设置新密码
    requestEditPassword(context, payload) {
      return services.postEditPassword(payload).then(res => {
        return res;
      })
    },
    // 重置密码
    requestResetPassword(context, payload) {
      return services.postResetPassword(payload).then(res => {
        return res;
      })
    },
    // 退出登录
    requestLogout(context, payload){
      // let school_code = localStorage.getItem('schoolCode')
      let params = {...payload}
      // params.from = `${services.APPS_SERVER}/${school_code}/login`
      return services.appsLogout(params).then(res => {
        if(res.code == '0000'){
          utils.removeUserInfo()
          if(res.data && res.data.redirect){
            window.open(`${res.data.redirect}`, '_self')
          } 
        }
        return res;
      })
    },
    // 学校使用自己的统一登录时 - 因和第三方学校登录集成，此接口做判断是否跳转
    schoolLoginRedirect(context, payload) {
      return services.schoolLoginRedirect(payload).then(res => {
        return res;
      })
    }
  },
  mutations: {
    setImageCode(state, payload) {
      state.imageCode = payload.imageCode;
    },
    setSchoolInfo(state, payload) {
      state.schoolInfo = payload.data;
    },
    setLoginUserInfo (state, payload) {
      state.loginUserInfo = payload.userInfo;
    },
  }
}