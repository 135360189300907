<template>
  <div class="right-form-box">
    <div class="resetPass-box">
      <div class="come-back" @click="backLogin()"></div>
      <div class="login-ways-box">
        <div class="ways-option active">重置密码</div>
      </div>
      <!-- 账号登录 -->
      <div class="pane-form">
        <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="90px">
          <el-form-item label="" prop="mobile" :error="mobileFormErrors.mobile">
            <label slot="label">手&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;&nbsp;&nbsp;号</label>
            <el-input v-model="mobileForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item class="captcha-lines" prop="captcha" :error="mobileFormErrors.captcha">
            <label slot="label">验&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;&nbsp;码</label>
            <el-input v-model="mobileForm.captcha" placeholder="请输入验证码"></el-input>
            <div class="captcha-box" @click="methodImageCaptcha()">
              <div class="captcha-img"><img :src="imageCode.img" /></div>
            </div>
          </el-form-item>
          <el-form-item class="captcha-lines" label="短信验证码" prop="code" :error="mobileFormErrors.code">
            <el-input v-model="mobileForm.code" placeholder="请输入短信验证码"></el-input>
            <div class="captcha-box" @click="clickloginSms()">
              <span :class="loginSmsClass">{{ loginSmsText }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="password" :error="mobileFormErrors.password">
            <label slot="label">设置新密码</label>
            <el-input v-model="mobileForm.password" type="password" autocomplete="off" placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item prop="password_confirmation" :error="mobileFormErrors.password_confirmation">
            <label slot="label">确认新密码</label>
            <el-input v-model="mobileForm.password_confirmation" type="password" autocomplete="off" placeholder="请再次输入新密码"></el-input>
          </el-form-item>
        </el-form>
        <div class="login-btn" @click="submitForm('mobileForm')">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import Footer from "@/components/Footer.vue";
// import LoginBanner from "@/components/LoginBanner.vue";

export default {
  name: 'ResetPassword',
  // components: {Footer, LoginBanner},
  data () {
    let validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error("密码为[8-16]位字母大小写及数字组合"));
        } else {
          callback();
        }
      }
    };
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[0-9]{10}$/

        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.mobileForm.password) {
        callback(new Error("两次输入的密码不相同"));
      } else {
        callback();
      }
    };
    return {
      mobileForm: {
        mobile: "",
        captcha: "",
        code: "",
        password: '',
        password_confirmation: '',
        _method: 'put',
      },
      mobileRules: {
        mobile: [{ validator: validatePhone, trigger: ['blur', 'change'] }],
        password: [{ validator: validatePassword, trigger: ['blur', 'change'] }],
        password_confirmation: [{ validator: validatePass2, trigger: ['blur', 'change'] }],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入验证码"));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入短信验证码"));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
      },
      mobileFormErrors: {
        captcha: "",
        mobile: "",
        code: "",
        password: '',
        password_confirmation: '',
      },
      school_code: localStorage.getItem("schoolCode"),
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "captcha-txt", // captcha-time
      imageCode: {
        key: '',
        img: '',
      }
    };
  },
  mounted () {
    let that = this
    if (that.school_code) {
      that.getLoginSchoolInfo({ school_code: that.school_code }).then(res => {
        if (res.code == '0000') {
          if (res.data && res.data.is_school_exist) {
            that.methodImageCaptcha()
          }
        }
      })
    }
  },
  methods: {
    ...mapActions(["getImageCaptcha", "requestResetPassword", "getSmsCode", "getLoginSchoolInfo",]),
    backLogin () {
      this.$router.go(-1);
    },
    // 请求图片验证码
    methodImageCaptcha () {
      let that = this;
      that.getImageCaptcha().then((res) => {
        if (res.code == '0000') {
          that.imageCode = res.data
        }
      });
    },
    // 提交表单事件
    submitForm (formname) {
      let that = this;
      that.$refs[formname].validate((valid) => {
        if (valid) {
          that.methodlogin(formname);
        } else {
          return false;
        }
      });
    },
    // 请求登录方法
    async methodlogin (formname) {
      let that = this;
      let param = {
        _method: 'put',
        school_code: that.school_code,
        password: that.mobileForm.password,
        mobile: that.mobileForm.mobile,
        code: that.mobileForm.code,
        password_confirmation: that.mobileForm.password_confirmation,
      };
      that.requestResetPassword(param).then((res) => {
        if (res.code == '0000') {
          that.$successMsg(res.message ? res.message : "操作成功")
          that.backLogin()
        } else {
          if (res.errors) {
            that[formname + 'Errors'] = { ...res.errors }
            that.methodImageCaptcha()
          } else {
            that.$errorMsg(res.message ? res.message : "操作失败")
          }
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms () {
      let that = this;
      if (that.loginSmsClass === "captcha-txt") {
        let params = {};
        that.mobileFormErrors = {}
        if (that.mobileForm.mobile) {
          const reg = /^1[0-9]{10}$/
          if (!reg.test(that.mobileForm.mobile)) {
            that.$set(this.mobileFormErrors, "mobile", "请输入正确的手机号");
          } else {
            params.mobile = that.mobileForm.mobile;
          }
        } else {
          this.$set(this.mobileFormErrors, "mobile", "请输入手机号");
          return false;
        }
        if (that.mobileForm.captcha) {
          params.captcha = that.mobileForm.captcha;
        } else {
          that.$set(this.mobileFormErrors, "captcha", "请输入验证码");
          return false;
        }
        params.key = that.imageCode.key;
        params.school_code = that.school_code
        that.getSmsCode(params).then((res) => {
          if (res.code === "0000") {
            that.smsCountDown()
            that.$successMsg(res.message ? res.message : "短信验证码发送成功");
          } else {
            if (res.errors) {
              that.mobileFormErrors = { ...res.errors }
              that.methodImageCaptcha()
            } else {
              that.$errorMsg(res.message ? res.message : "短信验证码发送失败")
            }
          }
        });
      }
    },
    // 验证码倒计时
    smsCountDown () {
      let that = this
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "captcha-time";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "captcha-txt";
          clearInterval(that.interval1);
        }
      }, 1000);
    }
  },
}
</script>

<style lang="less" scoped>
.resetPass-box {
  position: relative;
}
.come-back {
  cursor: pointer;
  width: 8px;
  height: 16px;
  background: url('../../assets/imgs/login/back.png') no-repeat center;
  background-size: 100% auto;
  position: absolute;
  top: 30px;
  left: -20px;
}
.login-ways-box {
  width: 100%;
  text-align: center;
  padding: 70px 0 36px;
  .ways-option {
    font-size: 20px;
    font-weight: 400;
    color: #757575;
    padding: 0 0 10px;
    display: inline-block;
    cursor: pointer;
    &.active {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      position: relative;
      border-bottom: 3px solid #125cfb;
    }
  }
}
.forget-box {
  font-size: 14px;
  color: #125cfb;
  text-decoration: none;
  text-align: left;
  display: block;
  margin: 6px 0 28px;
}
.login-btn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #125cfd;
  border-radius: 3px;
  font-size: 16px;
  margin-top: 30px;
  letter-spacing: 20px;
  text-indent: 20px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: #094ee5;
  }
  &.paddingTop {
    margin-top: 70px;
  }
}

// 重写el-form样式
.el-form-item {
  background: #ffffff;
  border: 1px solid #f1f2f7;
  margin-bottom: 16px;
  /deep/ .el-form-item__label {
    color: #333333;
    font-size: 14px;
    padding: 0 5px 0 0;
  }
  &.captcha-lines {
    /deep/ .el-input {
      width: calc(100% - 90px);
    }
    .captcha-box {
      width: 80px;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      .captcha-img {
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
        }
      }
      .captcha-txt {
        color: #125cfd;
        cursor: pointer;
        display: inline-block;
      }
      .captcha-time {
        color: #b6b8bd;
        display: inline-block;
      }
    }
  }

  /deep/ .el-input__inner {
    border: none !important;
  }
  /deep/ .el-form-item__error {
    padding-left: 15px;
  }
}

/deep/ .el-carousel__indicators--horizontal {
  bottom: 40px;
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 0;
}
/deep/ .el-carousel__indicator.is-active button {
  opacity: 1;
}
/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 50%;
  margin-right: 10px;
}
.resetPass-box {
  /deep/ .el-form-item__error {
    padding-left: 0;
    margin-left: -75px;
  }
}
</style>