import services from '@/assets/js/services'

export default {
  state: {
    manageAllApp: [],
  },
  getters: {
  },
  actions: {
    // 用户管理- 筛选项 应用名称
    requestManageAllApp(context, payload) {
      return services.getSchoolAppList(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setManageAllApp', {
            'data': res.data ? res.data : null
          })
        }
        return res;
      })
    },
    // 用户管理-获取用户列表、详情
    requestManageUserList(context, payload) {
      return services.getManageUserList(payload).then(res => {
        return res;
      })
    },
    // 用户管理-修改用户信息
    editManageUser(context, payload) {
      return services.updateManageUser(payload).then(res => {
        return res;
      })
    },
    // 用户管理-用户密码启用、停用
    requestUserStatus(context, payload) {
      return services.updateUserStatus(payload).then(res => {
        return res;
      })
    },
    // 用户管理-用户密码重置
    requestUserResetPass(context, payload) {
      return services.resetUserPassword(payload).then(res => {
        return res;
      })
    },
    // 用户管理-获取应用列表
    requestManageAppList(context, payload) {
      return services.getUserAppList(payload).then(res => {
        return res;
      })
    },
    // 用户管理-操作日志列表
    requestManageLogList(context, payload) {
      return services.getUserLogList(payload).then(res => {
        return res;
      })
    },
  },
  mutations: {
    setManageAllApp (state, payload) {
      if(payload.data){
        let newarr = []
        payload.data.forEach(element => {
          if(element.is_use == 1) {
            newarr.push(element)
          }
        });
        state.manageAllApp = newarr
      }
    },
  }
}