<template>
  <div class="worker-all-box">
    <Header />
    <div class="work-middle-box" ref="middleBox">
      <div class="work-middle-inner">
        <div class="top-head">
          <img src="@/assets/imgs/worker/bg-head.png" />
        </div>
        <div v-if="!utils.isMobile()&&isShowMessage" class="message-tips">
          <div class="message-text">想要解锁更多平台内容，请联系网站沟通。我们会第一时间联系您！</div>
          <div class="close-message" @click="isShowMessage = !isShowMessage"></div>
          <div class="contact-btn">
            <span @click="isShowContact = !isShowContact">立即联系</span>
          </div>
          <div v-show="isShowContact" class="sub-contact-box">
            <div class="contact-label">联系我们</div>
            <div class="right-close" @click="isShowContact = !isShowContact"></div>
            <div class="bottom-con">
              <div class="left-ways">
                <div class="ways-bold">CONTACT US</div>
                <div class="name">联系人：王老师</div>
                <div class="phone">电话：131 6151 0966</div>
              </div>
              <div class="right-erweima"></div>
            </div>
          </div>
        </div>

        <div class="application-box">
          <div class="app-label">我的应用</div>
          <template>
            <template v-if="!utils.isMobile()">
              <div class="application-list">
                <template v-for="(item, index) in useAppList">
                  <div class="app-items" @click="linkRouter(item)" v-if="index < 4" :key="index">
                    <a>
                      <div class="left-imgs">
                        <img :src="item.icon" />
                      </div>
                      <div class="right-intro">
                        <div class="app-name">{{item.name}}</div>
                        <div class="app-info">{{item.intro}}</div>
                        <div class="app-time">有效时间：{{item.start_date}}~{{item.end_date}}</div>
                      </div>
                    </a>
                  </div>
                </template>
                <div class="app-seize"></div>
                <div class="app-seize"></div>
              </div>
              <div v-if="useAppList.length > 4" class="application-list border">
                <template v-for="(item, index) in useAppList">
                  <div class="app-items" @click="linkRouter(item)" v-if="index > 3" :key="index">
                    <a>
                      <div class="left-imgs">
                        <img :src="item.icon" />
                      </div>
                      <div class="right-intro">
                        <div class="app-name">{{item.name}}</div>
                        <div class="app-info">{{item.intro}}</div>
                        <div class="app-time">有效时间：{{item.start_date}}~{{item.end_date}}</div>
                      </div>
                    </a>
                  </div>
                </template>
                <div class="app-seize"></div>
                <div class="app-seize"></div>
              </div>
            </template>
            <div v-else class="application-list">
              <template v-for="(item, index) in useAppList">
                <div class="app-items" @click="linkRouter(item)" :key="index">
                  <a>
                    <div class="left-imgs">
                      <img :src="item.icon" />
                    </div>
                    <div class="right-intro">
                      <div class="app-name">{{item.name}}</div>
                      <div class="app-info">{{item.intro}}</div>
                      <div class="app-time">有效时间：{{item.start_date}}~{{item.end_date}}</div>
                    </div>
                  </a>
                </div>
              </template>
              <div class="app-seize"></div>
              <div class="app-seize"></div>
            </div>

          </template>

        </div>

        <div v-if="!utils.isMobile()&&notUseApp.length > 0" class="application-box">
          <div class="app-label">推荐应用</div>
          <div class="application-list recommend">
            <template v-for="(item, index) in notUseApp">
              <div class="app-items" v-if="index < 4" :key="index">
                <div class="left-imgs">
                  <img :src="item.icon2" />
                </div>
                <div class="right-intro">
                  <div class="app-name">{{item.name}}</div>
                  <div class="app-info">{{item.intro}}</div>
                </div>
              </div>
            </template>
            <div class="app-seize"></div>
            <div class="app-seize"></div>
          </div>
          <div v-if="notUseApp.length > 4" class="application-list recommend border">
            <template v-for="(item, index) in notUseApp">
              <div class="app-items" v-if="index > 3" :key="index">
                <div class="left-imgs">
                  <img :src="item.icon2" />
                </div>
                <div class="right-intro">
                  <div class="app-name">{{item.name}}</div>
                  <div class="app-info">{{item.intro}}</div>
                </div>
              </div>
            </template>
            <div class="app-seize"></div>
            <div class="app-seize"></div>
          </div>
        </div>

      </div>

    </div>
    <Footer :isFixed='isFooterFixed' />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import { mapState, mapActions } from "vuex";
import utils from "@/assets/js/utils.js"

export default {
  name: 'worker',
  components: { Footer, Header },
  data () {
    return {
      isShowContact: false,
      isShowMessage: true,
      middleHeight: 0,
      isFooterFixed: false,
      utils
    };
  },
  computed: {
    ...mapState({
      useAppList: (state) => state.user.useAppList,
      notUseApp: (state) => state.user.notUseApp,
    }),
  },
  mounted () {
    let that = this
    that.$openLoading()
    this.requestSchoolAppList().then(() => {
      that.$closeLoading()
    })

    this.$nextTick(() => {
      that.setFooterHeight()
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
  },
  methods: {
    ...mapActions(["requestSchoolAppList",]),
    linkRouter (item) {
      if (item.url) {
        window.open(item.url, "_blank")
      }
    },
    setFooterHeight () {
      let middleHeight = this.$refs.middleBox.offsetHeight;
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if ((hth - 160) > middleHeight) {
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.worker-all-box {
    width: 100%;
    min-height: 100%;
    background: #f7f8f9;
}
.work-middle-box {
    width: 100%;
    padding: 20px 0 30px;
    height: calc(100% - 160px);
    min-height: 600px;
    box-sizing: border-box;
    position: relative;
}
.work-middle-inner {
    min-width: 1300px;
    margin: 0 auto;
    width: 78%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    .top-head {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .message-tips {
        width: 100%;
        height: 40px;
        background: #eff4ff;
        border: 1px solid #dee5fc;
        border-radius: 2px;
        position: relative;
        text-align: left;
        .message-text {
            font-size: 14px;
            color: #8a8ea4;
            line-height: 40px;
            display: inline-block;
            &::before {
                content: '';
                display: inline-block;
                width: 25px;
                height: 22px;
                background: url('../../assets/imgs/worker/tips.png') no-repeat
                    center;
                background-size: 100% auto;
                margin: 0px 10px 0 20px;
                vertical-align: -7px;
            }
        }
        .contact-btn {
            float: right;
            font-size: 14px;
            color: #506bed;
            line-height: 40px;
            text-align: right;
            display: inline-block;
            cursor: pointer;
        }
        .sub-contact-box {
            background: #ffffff;
            box-shadow: 0px 0px 15px 0px rgba(140, 149, 159, 0.35);
            border-radius: 6px;
            text-align: left;
            position: absolute;
            right: 0;
            top: 40px;
            z-index: 10;
            transition: all 0.5s;
            .contact-label {
                font-size: 20px;
                font-weight: bold;
                font-style: italic;
                color: #16314d;
                position: relative;
                display: block;
                border-bottom: 1px solid #edeef0;
                height: 50px;
                line-height: 50px;
                padding: 0 25px;
                &::after {
                    content: '';
                    width: 25px;
                    height: 21px;
                    display: inline-block;
                    background: url('../../assets/imgs/worker/contact.png')
                        no-repeat center;
                    background-size: 100% auto;
                }
            }
            .right-close {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url('../../assets/imgs/worker/gray-close.png')
                    no-repeat center;
                background-size: 13px auto;
                cursor: pointer;
                position: absolute;
                right: 25px;
                top: 15px;
            }
            .bottom-con {
                padding: 15px 25px 20px;
                .left-ways {
                    display: inline-block;
                    .ways-bold {
                        font-weight: bold;
                        color: #f8faff;
                        font-size: 36px;
                        margin-bottom: 25px;
                    }
                    .name {
                        font-weight: bold;
                        color: #343639;
                        font-size: 14px;
                        line-height: 24px;
                    }
                    .phone {
                        color: #343639;
                        font-size: 14px;
                        line-height: 24px;
                    }
                    .email {
                        color: #343639;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
                .right-erweima {
                    width: 108px;
                    height: 108px;
                    display: inline-block;
                    background: url('../../assets/imgs/worker/erweima.jpg')
                        no-repeat center;
                    background-size: 100% auto;
                    margin-left: 20px;
                }
            }
        }
        .close-message {
            float: right;
            width: 11px;
            height: 40px;
            cursor: pointer;
            display: inline-block;
            background: url('../../assets/imgs/worker/close.png') no-repeat
                center;
            background-size: 100% auto;
            margin: 0 20px;
        }
    }
    .application-box {
        width: 100%;
        text-align: left;
        background: #ffffff;
        margin: 10px 0 20px;
        .app-label {
            font-size: 22px;
            color: #161940;
            padding: 15px 0 0;
            &::before {
                content: '';
                display: inline-block;
                width: 4px;
                height: 22px;
                background: #4a55e6;
                margin-right: 16px;
                vertical-align: -3px;
            }
        }
        .application-list {
            padding: 30px 0;
            margin: 0 30px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &.border {
                border-top: 2px dashed #f7f8f9;
            }
            .app-items {
                flex: 25%;
                cursor: pointer;
                .left-imgs {
                    display: inline-block;
                    vertical-align: top;
                    width: 60px;
                    height: 61px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .right-intro {
                    display: inline-block;
                    margin-left: 7px;
                    .app-name {
                        font-size: 16px;
                        font-weight: bold;
                        color: #161940;
                        margin-bottom: 6px;
                        max-width: 192px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .app-info {
                        font-size: 12px;
                        color: #aaabb5;
                        max-width: 192px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .app-time {
                        color: #d5bb88;
                        font-size: 12px;
                        max-width: 195px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                &:hover a .app-name {
                    color: #125cfd;
                }
            }
            .app-seize {
                flex: 0 0 25%;
                display: inline-block;
                overflow: visible;
            }
        }
        .application-list.recommend {
            padding: 30px 0 30px;
            .app-name {
                color: #6e7083 !important;
            }
            .app-info {
                color: #aaabb5 !important;
            }
        }
        .application-list.recommend .app-items:hover .app-name {
            color: #151940 !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .work-middle-box {
        padding: 10px 0 15px;
        min-height: auto;
        width: 100%;
    }
    .work-middle-inner {
        min-width: auto;
        width: 100%;
    }
    .work-middle-inner {
        .application-box {
          min-height: 50vh;
            .app-label {
                font-size: 18px;
            }
            .application-list {
                margin: 0 14px;
                .app-items {
                    flex: 100%;
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                    .left-imgs {
                        width: 45px;
                        height: auto;
                    }
                    .right-intro {
                        margin-left: 10px;
                        .app-name {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .top-head {
            overflow: hidden;
            img {
                width: auto;
                height: 70px;
            }
        }
    }
}
</style>