<template>
  <div>
    <vue-base-dialog :dialogConfig="dialogConfig" class="selfCreateDialog" ref="childDialog" @close="parentModalFunc">
      <div class="dialog-content">
        <div class="top-progress-box">
          <div :class="step >= 1 ? 'circular-box active' : 'circular-box'">
            <div class="icon-box">
              <div class="step-box">1</div>
            </div>
            <div class="step-text">验证手机号</div>
          </div>
          <div class="line-box"></div>
          <div :class="step >= 2 ? 'circular-box active' : 'circular-box'">
            <div class="icon-box">
              <div class="step-box">2</div>
            </div>
            <div class="step-text">绑定新手机号</div>
          </div>
          <div class="line-box"></div>
          <div :class="step == 3 ? 'circular-box active' : 'circular-box'">
            <div class="icon-box">
              <div class="step-box">3</div>
            </div>
            <div class="step-text">绑定成功</div>
          </div>
        </div>

        <!-- 验证手机号 -->
        <div v-if="step == 1" class="center-form-box">
          <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="88px">
            <el-form-item prop="mobile" :error="mobileFormErrors.mobile">
              <label slot="label">原&nbsp;手&nbsp;机&nbsp;号</label>
              <el-input v-model="mobileForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="captcha" :error="mobileFormErrors.captcha" class="captcha-lines">
              <label slot="label">图形验证码</label>
              <el-input v-model="mobileForm.captcha" placeholder="请输入验证码"></el-input>
              <div class="captcha-box" @click="methodImageCaptcha()">
                <div class="captcha-img"><img :src="imageCode.img" /></div>
              </div>
            </el-form-item>
            <el-form-item prop="code" :error="mobileFormErrors.code" class="captcha-lines" label="短信验证码">
              <el-input v-model="mobileForm.code" placeholder="请输入短信验证码"></el-input>
              <div class="captcha-box" @click="clickloginSms()">
                <span :class="loginSmsClass">{{ loginSmsText }}</span>
              </div>
            </el-form-item>
          </el-form>
          <div class="right-edit-btn" @click="submitForm(1)">下一步</div>
        </div>

        <!-- 绑定新手机号 -->
        <div v-if="step == 2" class="center-form-box">
          <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="88px">
            <el-form-item prop="mobile" :error="mobileFormErrors.mobile">
              <label slot="label">新&nbsp;手&nbsp;机&nbsp;号</label>
              <el-input v-model="mobileForm.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="captcha" :error="mobileFormErrors.captcha" class="captcha-lines">
              <label slot="label">图形验证码</label>
              <el-input v-model="mobileForm.captcha" placeholder="请输入验证码"></el-input>
              <div class="captcha-box" @click="methodImageCaptcha()">
                <div class="captcha-img"><img :src="imageCode.img" /></div>
              </div>
            </el-form-item>
            <el-form-item prop="code" :error="mobileFormErrors.code" class="captcha-lines" label="短信验证码">
              <el-input v-model="mobileForm.code" placeholder="请输入短信验证码"></el-input>
              <div class="captcha-box" @click="clickloginSms()">
                <span :class="loginSmsClass">{{ loginSmsText }}</span>
              </div>
            </el-form-item>
          </el-form>
          <div class="right-edit-btn" @click="submitForm(2)">确认修改</div>
        </div>

        <!-- 绑定成功 -->
        <div v-if="step == 3" class="complete-form-box">
          <div class="success-message">
            <div class="message-title">绑定成功！</div>
            <p class="message-con">
              您的手机号已修改为{{mobileForm.mobile | filterMobile}}<br />您可以使用该手机号码登录账号、找回密码
            </p>
            <div class="message-btn" @click="cancelModal()">确定</div>
          </div>
        </div>
      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
import { mapActions, mapState } from "vuex";

export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
  },
  components: {
    VueBaseDialog,
  },
  data () {
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[0-9]{10}$/
        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          if (this.step == 2 && this.loginUserInfo.mobile == value) {
            callback(new Error("新手机号与旧手机号一样"));
          } else if (this.step == 1 && this.loginUserInfo.mobile != value) {
            callback(new Error('原手机号错误'));
          } else {
            callback();
          }
        }
      }
    };
    return {
      step: 1,
      imageCode: {
        img: "",
        key: "",
      },
      mobileForm: {
        mobile: "",
        code: "",
        captcha: '',
      },
      mobileRules: {
        mobile: [{ validator: validatePhone, trigger: ['blur', 'change'] }],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                callback();
              } else {
                callback(new Error("请输入验证码"));
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入短信验证码"));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
      },
      mobileFormErrors: {
        captcha: "",
        mobile: "",
        code: "",
      },
      captchaErrors: '',
      mobileErrors: '',
      code: '',
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "captcha-txt", // captcha-time
      school_code: localStorage.getItem("schoolCode"),
    }
  },
  filters: {
    filterMobile (val) {
      let reg = /^(.{3}).*(.{4})$/;
      return val.replace(reg, '$1****$2')
    }
  },
  computed: {
    ...mapState({
      loginUserInfo: (state) => state.login.loginUserInfo,
    }),
  },
  mounted () {
    this.methodImageCaptcha();
  },
  methods: {
    ...mapActions(["getImageCaptcha", "requestUpdateUserPhone", "getSmsCode"]),
    cancelModal () {
      this.$emit("cancleDialog");
      this.$emit('refreshData')
    },
    parentModalFunc () { },
    // 请求图片验证码
    methodImageCaptcha () {
      let that = this;
      that.getImageCaptcha().then((res) => {
        if (res.code == "0000") {
          that.imageCode = res.data;
        }
      });
    },

    // 提交表单事件
    submitForm (currentStep) {
      let that = this;
      that.$refs["mobileForm"].validate((valid) => {
        if (valid) {
          that.mobileFormErrors = {}
          let param = {
            _method: "put",
            act: currentStep == 1 ? 'check' : "bind", // 类型 check校验 bind绑定
            mobile: that.mobileForm.mobile,
            code: that.mobileForm.code,
          }
          that.requestUpdateUserPhone(param).then(res => {
            if (res.code == '0000') {
              if (currentStep == 1) {
                that.$refs['mobileForm'].resetFields();
                that.step = 2
                that.interval1 && clearInterval(that.interval1);
                that.loginSmsNum = 60,
                  that.loginSmsText = "获取验证码"
                that.loginSmsClass = "captcha-txt"
                that.methodImageCaptcha();
              } else {
                that.step = 3
              }
            } else {
              that.$refs["mobileForm"].clearValidate()
              that.methodImageCaptcha()
              if (res.errors) {
                that.mobileFormErrors = res.errors
              } else {
                that.$errorMsg(res.message ? res.message : "操作失败")
              }
            }
          })
        } else {
          return false;
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms () {
      let that = this;
      if (that.loginSmsClass === "captcha-txt") {
        let params = {};
        that.mobileFormErrors = {}
        if (that.mobileForm.mobile) {
          const reg = /^1[0-9]{10}$/
          if (!reg.test(that.mobileForm.mobile)) {
            that.$set(this.mobileFormErrors, "mobile", "请输入正确的手机号");
            return false;
          } else {
            if (that.step == 2 && that.loginUserInfo.mobile == that.mobileForm.mobile) {
              that.$set(this.mobileFormErrors, "mobile", "新手机号与旧手机号一样");
              return false;
            } else if (that.step == 1 && that.loginUserInfo.mobile != that.mobileForm.mobile) {
              that.$set(this.mobileFormErrors, "mobile", '原手机号错误');
              return false;
            } else {
              params.mobile = that.mobileForm.mobile;
            }
          }
        } else {
          this.$set(this.mobileFormErrors, "mobile", "请输入手机号");
          return false;
        }
        if (that.mobileForm.captcha) {
          params.captcha = that.mobileForm.captcha;
        } else {
          that.$set(this.mobileFormErrors, "captcha", "请输入验证码");
          return false;
        }
        params.key = that.imageCode.key;
        that.getSmsCode(params).then((res) => {
          if (res.code === "0000") {
            that.smsCountDown();
            that.$successMsg(res.message ? res.message : "短信验证码发送成功");
          } else {
            if (res.errors) {
              that.mobileFormErrors = res.errors;
              that.methodImageCaptcha();
            } else {
              that.$errorMsg(res.message ? res.message : "短信验证码发送失败");
            }
          }
        });
      }
    },
    // 验证码倒计时
    smsCountDown () {
      let that = this;
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "captcha-time";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "captcha-txt";
          clearInterval(that.interval1);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 60px;
}
.top-progress-box {
  padding: 0 0 40px;
  text-align: center;
}
.top-progress-box .circular-box {
  display: inline-block;
  width: 56px;
  height: 56px;
  position: relative;
}
.top-progress-box .circular-box .icon-box {
  width: 56px;
  height: 56px;
  background: #f7f7f8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-progress-box .circular-box.active .icon-box {
  background: #f2f6ff;
}
.top-progress-box .circular-box .icon-box .step-box {
  width: 31px;
  height: 31px;
  background: #f7f7f8;
  border: 2px solid #ececec;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  color: #dfdfe1;
  line-height: 31px;
}
.top-progress-box .circular-box.active .icon-box .step-box {
  border-color: #bfd3ff;
  background: #f2f6ff;
  color: #abc5ff;
}
.top-progress-box .circular-box .step-text {
  font-size: 14px;
  color: #aeaeae;
  font-weight: bold;
  position: absolute;
  bottom: -30px;
  width: 90px;
  text-align: center;
  left: -15px;
}
.top-progress-box .circular-box.active .step-text {
  color: #545454;
}
.top-progress-box .line-box {
  width: 130px;
  height: 0;
  border: 2px dotted #f1f2f7;
  position: relative;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.center-form-box {
  padding: 40px 0 20px;
  // 重写el-form样式
  .el-form-item {
    background: #ffffff;
    border: 1px solid #f1f2f7;
    /deep/ .el-form-item__label {
      color: #333333;
      font-size: 14px;
      padding: 0 5px 0 0;
    }

    /deep/ .el-input__inner {
      border: none !important;
    }
    /deep/ .el-form-item__error {
      padding-left: 15px;
    }
    &.captcha-lines {
      /deep/ .el-input {
        width: calc(100% - 90px);
      }
      .captcha-box {
        width: 80px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        .captcha-img {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          cursor: pointer;
          img {
            width: 100%;
            height: auto;
          }
        }
        .captcha-txt {
          color: #125cfd;
          cursor: pointer;
          display: inline-block;
        }
        .captcha-time {
          color: #b6b8bd;
          display: inline-block;
        }
      }
    }
  }
  .right-edit-btn {
    width: 114px;
    height: 32px;
    font-size: 14px;
    background: #f2f6ff;
    margin-bottom: 30px;
    border: 1px solid #2462f5;
    color: #125cfd;
    line-height: 32px;
    cursor: pointer;
    margin: 50px auto 0;
    text-align: center;
    &:hover {
      background: #ffffff;
    }
  }
}
.complete-form-box {
  padding: 0 0 20px;
  .success-message {
    padding: 50px 0 0px;
    .message-title {
      font-size: 18px;
      font-weight: bold;
      color: #125cfd;
      margin-bottom: 20px;
    }
    .message-con {
      font-size: 14px;
      color: #3a3f4a;
      margin-bottom: 80px;
      line-height: 24px;
    }
  }
  .message-btn {
    width: 98px;
    height: 32px;
    font-size: 14px;
    background: #f2f6ff;
    margin-bottom: 30px;
    border: 1px solid #2462f5;
    color: #125cfd;
    line-height: 32px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
    &:hover {
      background: #ffffff;
    }
  }
}
// 适配h5
@media screen and (max-width: 600px) {
  .top-progress-box .line-box {
    width: 68px !important;
  }
  .top-progress-box .circular-box {
    width: 36px !important;
    height: 36px !important;
  }
  .top-progress-box .circular-box .step-text {
    font-size: 13px !important;
    left: -25px !important;
  }
  .top-progress-box .circular-box .icon-box {
    width: 36px !important;
    height: 36px !important;
  }
  .dialog-content {
    padding: 20px 0 !important;
  }
  .top-tips {
    width: 80% !important;
    margin: 0 auto 30px !important;
  }
  .center-form-box {
    width: 80% !important;
    margin: 0 auto !important;
    box-sizing: border-box !important;
  }
  .complete-form-box {
    padding: 0 34px 20px !important;
    width: 75vw !important;
  }
}
</style>