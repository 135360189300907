<template>
  <div class="userManage-all-box">
    <Header />
    <div ref="middleBox" class="userNanage-middle-box">
      <div class="userNanage-inner-box">
        <div class="top-router-box">
          <div class="router-name">用户管理</div>
          <!-- <template v-for="(item, index) in menuList">
            <div class="router-name" :key="index">{{$route.path.indexOf(item.path) > -1 ? item.name : ''}}</div>
          </template> -->
        </div>
        <div class="manage-outer-box">
          <div class="manage-menu-list">
            <template v-for="(item, index) in menuList">
              <router-link :key="index" :to="`/${school_code}${item.path}`" :class="$route.path.indexOf(item.path) > -1  ? 'manage-menu-item active' : 'manage-menu-item'">
                <img class="menu-icon" :src="item.icon" />
                <span>{{ item.name }}</span>
              </router-link>
            </template>
          </div>
          <router-view v-on:onTableHeight="setTableHeight" />
        </div>
      </div>
    </div>
    <Footer :isFixed='isFooterFixed'  />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: "UserManageLayout",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      isFooterFixed: false,
      menuList: [
        {
          name: "用户列表",
          icon: require("@/assets/imgs/userManage/userlist.png"),
          path: '/userManage/userList'
        },
        // {
        //   name: "管理员列表",
        //   icon: require("@/assets/imgs/userManage/managelist.png"),
        //   path: '/userManage/administrators',
        // },
        {
          name: "应用列表",
          icon: require("@/assets/imgs/userManage/applist.png"),
          path: '/userManage/application',
        },
        {
          name: "操作日志",
          icon: require("@/assets/imgs/userManage/optlog.png"),
          path: '/userManage/operationLog'
        },
      ],
      school_code: localStorage.getItem('schoolCode'),
    };
  },
  created (){
    
    
  },
  mounted() {
    
  },
  methods: {
    setTableHeight(val){
      this.isFooterFixed = val
    }
  }
};
</script>

<style lang="less" scoped>
.userManage-all-box {
  width: 100%;
  background: #f7f8f9;
  position: relative;
}
.userNanage-middle-box {
  width: 100%;
  margin-bottom: 30px;
  .userNanage-inner-box {
    width: 1300px;
    margin: 0 auto;
    .top-router-box {
      width: 100%;
      height: 60px;
      background: url("../../assets/imgs/userManage/bg-user.png") no-repeat
        center;
      background-size: 100% auto;
      margin: 15px auto;
      .router-name {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        line-height: 60px;
        margin-left: 25px;
      }
    }
    .manage-outer-box {
      width: 100%;
      min-height: 640px;
      display: flex;
      background: #f2f6ff;
      .manage-menu-list {
        border: 1px solid #e7e9f8;
        border-right: none;
        width: 180px;
        padding: 35px 10px 0;
        .manage-menu-item {
          display: block;
          height: 40px;
          line-height: 40px;
          text-align: left;
          margin-bottom: 20px;
          padding: 0 13px;
          a {
            color: #979ba8;
            font-size: 16px;
            text-decoration: none;
          }
          span {
            color: #979ba8;
            font-size: 16px;
          }
          .menu-icon {
            width: 25px;
            height: auto;
            vertical-align: -7px;
            margin-right: 10px;
          }
          &:hover,
          &.active {
            background: #ffffff;
            font-weight: bold;
            color: #26282c;
            span {
              color: #26282c;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>