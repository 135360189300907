<template>
  <div class="right-form-box">
    <div class="login-ways-box">
      <div :class="loginWay == 1 ? 'ways-option active' : 'ways-option'" @click="changeLoginWay(1)">
        账号登录
      </div>
      <div :class="loginWay == 2 ? 'ways-option active' : 'ways-option'" @click="changeLoginWay(2)">
        验证码登录
      </div>
    </div>
    <!-- 账号登录 -->
    <div v-show="loginWay == 1" class="pane-form account">
      <el-form ref="accountForm" :model="accountForm" :rules="accountRules" label-width="65px">
        <el-form-item label="" prop="username" :error="accountFormErrors.username">
          <label slot="label">账&nbsp;&nbsp;&nbsp;&nbsp;号</label>
          <el-input @keyup.enter.native="submitForm('accountForm')" v-model="accountForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password" :error="accountFormErrors.password">
          <label slot="label">密&nbsp;&nbsp;&nbsp;&nbsp;码</label>
          <el-input @keyup.enter.native="submitForm('accountForm')" type="password" v-model="accountForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item v-if="schoolInfo && schoolInfo.is_captcha_check" class="captcha-lines" prop="captcha" :error="accountFormErrors.captcha" label="验证码">
          <el-input @keyup.enter.native="submitForm('accountForm')" placeholder="请输入验证码" v-model="accountForm.captcha"></el-input>
          <div class="captcha-box" @click="getImageCaptcha()">
            <div class="captcha-img"><img :src="imageCode.img" /></div>
          </div>
        </el-form-item>
      </el-form>
      <router-link class="forget-box" :to="`/${school_code}/resetPassword`">忘记密码</router-link>
      <div class="login-btn" @click="submitForm('accountForm')">
        登录
      </div>
    </div>
    <!-- 验证码登录 -->
    <div v-show="loginWay == 2" class="pane-form mobile">
      <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="88px">
        <el-form-item prop="mobile" :error="mobileFormErrors.mobile">
          <label slot="label">手&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;&nbsp;&nbsp;号</label>
          <el-input @keyup.enter.native="submitForm('mobileForm')" v-model="mobileForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item class="captcha-lines" prop="captcha" :error="mobileFormErrors.captcha">
          <label slot="label">验&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;&nbsp;码</label>
          <el-input @keyup.enter.native="submitForm('mobileForm')" v-model="mobileForm.captcha" placeholder="请输入验证码"></el-input>
          <div class="captcha-box" @click="getImageCaptcha()">
            <div class="captcha-img"><img :src="imageCode.img" /></div>
          </div>
        </el-form-item>
        <el-form-item prop="code" :error="mobileFormErrors.code" class="captcha-lines" label="短信验证码">
          <el-input @keyup.enter.native="submitForm('mobileForm')" v-model="mobileForm.code" placeholder="请输入短信验证码"></el-input>
          <div class="captcha-box" @click="clickloginSms()">
            <span :class="loginSmsClass">{{ loginSmsText }}</span>
          </div>
        </el-form-item>
      </el-form>
      <router-link class="forget-box" :to="`/${school_code}/resetPassword`">忘记密码</router-link>
      <div class="login-btn" @click="submitForm('mobileForm')">
        登录
      </div>
    </div>

    <!-- <div class="wechat-box">
      <div class="top-box">
        <div class="text">您也可以用以下方式登录</div>
      </div>
      <div class="imgs-box" @click="wechatLogin()">
        <img src="@/assets/imgs/login/wechat.png" />
      </div>
    </div> -->
  </div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
// import LoginBanner from "@/components/LoginBanner.vue";
import { mapState, mapActions } from "vuex";
import services from '@/assets/js/services'
// import utils from '@/assets/js/utils'
import '../../assets/css/loginMobile.less'
// import VueCookies from 'vue-cookies';
export default {
  name: "Login",
  // components: {Footer, LoginBanner},
  data () {
    let validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error("密码为[8-16]位字母大小写及数字组合"));
        } else {
          callback();
        }
      }
    };
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[0-9]{10}$/
        if (!reg.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      services,
      loginWay: 1,
      accountForm: {
        username: "",
        password: "",
        captcha: "",
      },
      accountRules: {
        username: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入账号"));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        password: [{ validator: validatePassword, trigger: "blur" }],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入验证码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      accountFormErrors: {
        username: "",
        password: "",
        captcha: "",
      },
      mobileForm: {
        mobile: "",
        captcha: "",
        code: "",
      },
      mobileRules: {
        mobile: [{ validator: validatePhone, trigger: ['blur', 'change'] }],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入验证码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入短信验证码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      mobileFormErrors: {
        captcha: "",
        mobile: "",
        code: "",
      },
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "captcha-txt", // captcha-time
      school_code: '',
    };
  },
  computed: {
    ...mapState({
      imageCode: (state) => state.login.imageCode,
      schoolInfo: (state) => state.login.schoolInfo,
    }),
  },
  mounted () {
    let that = this
    if (this.$route.params && this.$route.params.schoolcode && !isNaN(this.$route.params.schoolcode)) {
      localStorage.setItem("schoolCode", this.$route.params.schoolcode);
      this.school_code = this.$route.params.schoolcode
    } else {
      localStorage.setItem("schoolCode", '')
      window.open(services.JIAOYU_CN, "_self")
    }
    // 获取登录学校的相关信息
    that.getLoginSchoolInfo({ school_code: that.school_code }).then(res => {
      if (res.code == '0000') {
        if (res.data) {
          // is_redirect 判断登录页面是否需要重定向
          if (res.data.is_redirect) {
            let param = { format: 'json', school_code: that.school_code }
            if (that.$route.query && that.$route.query.from) {
              param.from = that.$route.query.from
            } else {
              param.from = `${services.APPS_SERVER}/${that.school_code}/worker`
            }
            that.schoolLoginRedirect(param).then(res => {
              if (res.code == '0000' && res.data) {
                if (res.data.redirect) {
                  window.open(res.data.redirect, "_self")
                }
              }
            })
          } else {
            if (res.data.is_school_exist) {
              that.getImageCaptcha();
            } else {
              window.open(services.JIAOYU_CN, "_self")
            }
          }
        }
      } else {
        that.$errorMsg(res.message ? res.message : "错误")
      }
    })
  },
  methods: {
    ...mapActions(["getImageCaptcha", "requestLoginApps", "getLoginSchoolInfo", "getSmsCode", "getLoginUserInfo", 'schoolLoginRedirect']),
    // 切换登录方式事件
    changeLoginWay (val) {
      this.loginWay = val;
      this.$refs['accountForm'].resetFields();
      this.$refs['mobileForm'].resetFields();
    },
    // 提交表单事件
    submitForm (formname) {
      let that = this;
      that.$refs[formname].validate((valid) => {
        if (valid) {
          that[formname + 'Errors'] = {}
          that.methodlogin(formname);
        } else {
          return false;
        }
      });
    },
    // 请求登录方法
    async methodlogin (formname) {
      let that = this;
      let param = {
        captcha: that[formname].captcha,
        key: that.imageCode.key,
        school_code: that.school_code,
      };
      if (formname == "accountForm") {
        param.username = that[formname].username;
        param.password = that[formname].password;
      } else {
        param.mobile = that[formname].mobile;
        param.code = that[formname].code;
      }
      that.requestLoginApps(param).then((res) => {
        if (res.code == '0000') {
          // 登录成功后 from 判断来源并返回来源地址  
          if (that.$route.query && that.$route.query.from) {
            // 测试环境 全媒体的测试用的qmt66.jiaoyu.cn 需要加上token参数
            if ((process.env.NODE_ENV == 'development' || process.env.VUE_APP_FLAG === 'test') && that.$route.query.from.indexOf(".jiaoyu.cn") > -1) {
              window.open(`${that.$route.query.from}?api_token=${res.data.token}`, "_self")
            } else {
              window.open(this.$route.query.from, "_self")
            }
          } else {
            that.methodLoginUserInfo()
          }
        } else {
          that.getImageCaptcha()
          if (res.errors) {
            that[formname + 'Errors'] = res.errors
          } else {
            that.$errorMsg(res.message ? res.message : "登录失败")
          }
        }
      });
    },
    // 登录后获取用户信息
    methodLoginUserInfo () {
      let that = this
      that.getLoginUserInfo({ is_append: 1 }).then(res => {
        if (res.code == '0000') {
          if (res.data && res.data.pwd_force == 1) {
            // pwd_force 1 强制修改密码 2否
            that.$router.push({ path: `/${that.school_code}/newPassword` })
          } else {
            // if (utils.isMobile() && res.data && res.data.show_menu && res.data.show_menu.indexOf('xuexi') > -1) {
            //   window.open(`${services.XUEXI_SERVER}/center/${that.school_code}/user/center`, '_self')
            // } else {
            // 如果是院校100046&&是学生 登录后自动跳转到学习中心研学旅行页面
            if ((that.school_code == services.SXYXLX_CODE || that.school_code == services.YXLX_CODE) && res.data.user_type == 1) {
              return window.open(`${services.XUEXI_SERVER}/center/${that.school_code}/custom/sxyxlx`, "_self");
            } else {
              that.$router.push({ path: `/${that.school_code}/worker` })
            }
            // }
          }
        }
      })
    },
    // 微信登录方法
    wechatLogin () {
      if (process.env.NODE_ENV == 'development') {
        // 开发环境测试
        window.open('http://jw.jiaoyu.cn' + "/api/weixin/login?school_code=" + this.school_code, "_self")
      } else {
        // 生产环境
        window.open(services.HOST + "/api/weixin/login?school_code=" + this.school_code, "_self")
      }
    },
    // 点击获取短信验证码事件
    clickloginSms () {
      let that = this;
      if (that.loginSmsClass === "captcha-txt") {
        let params = {};
        that.mobileFormErrors = {}
        if (that.mobileForm.mobile) {
          const reg = /^1[0-9]{10}$/
          if (!reg.test(that.mobileForm.mobile)) {
            that.$set(this.mobileFormErrors, "mobile", "请输入正确的手机号");
          } else {
            params.mobile = that.mobileForm.mobile;
          }
        } else {
          this.$set(this.mobileFormErrors, "mobile", "请输入手机号");
          return false;
        }
        if (that.mobileForm.captcha) {
          params.captcha = that.mobileForm.captcha;
        } else {
          that.$set(this.mobileFormErrors, "captcha", "请输入验证码");
          return false;
        }
        params.key = that.imageCode.key;
        params.school_code = that.school_code
        that.getSmsCode(params).then((res) => {
          if (res.code === "0000") {
            that.smsCountDown()
            that.$successMsg(res.message ? res.message : "短信验证码发送成功");
          } else {
            if (res.errors) {
              that.mobileFormErrors = res.errors
              that.getImageCaptcha()
            } else {
              that.$errorMsg(res.message ? res.message : "短信验证码发送失败")
            }
          }
        });
      }
    },
    // 验证码倒计时
    smsCountDown () {
      let that = this
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "captcha-time";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "captcha-txt";
          clearInterval(that.interval1);
        }
      }, 1000);
    }
  },
};
</script>

<style lang="less" scoped>
.login-all-box {
    width: 100%;
    height: calc(100% - 90px);
}
.backgroud-box {
    width: 100%;
    height: 100%;
    background: url('../../assets/imgs/login/bg-login.jpg') no-repeat center;
    background-size: 100% auto;
    .login-header-box {
        padding: 20px 0;
        text-align: left;
        .school-logo {
            vertical-align: top;
            display: inline-block;
            margin-left: 34px;
            img {
                height: 46px;
                width: auto;
            }
        }
        .split-line {
            width: 2px;
            height: 21px;
            background: #dee3eb;
            display: inline-block;
            margin: 12px 20px 0;
            vertical-align: top;
        }
        .page-name {
            font-size: 20px;
            font-weight: bold;
            color: #393c44;
            line-height: 40px;
            display: inline-block;
            vertical-align: top;
        }
    }
}

.middle-con-box {
    width: 100%;
    height: calc(100% - 89px);
    position: relative;
    min-height: 630px;
    .middle-inner-box {
        width: 972px;
        height: 560px;
        margin: 0 auto;
        position: absolute;
        transform: translate(-50%, -53%);
        top: 50%;
        left: 50%;
        .right-form-box {
            width: 412px;
            height: 560px;
            background: #ffffff;
            border-radius: 0px 3px 3px 0px;
            vertical-align: top;
            display: inline-block;
            padding: 0 74px;
            box-sizing: border-box;
        }
    }
}
@media only screen and (max-height: 780px) {
    .backgroud-box {
        height: auto;
    }
}
.login-ways-box {
    width: 100%;
    text-align: center;
    padding: 90px 0 40px;
    .ways-option {
        font-size: 20px;
        font-weight: 400;
        color: #757575;
        padding: 0 0 10px;
        display: inline-block;
        cursor: pointer;
        &:first-child {
            margin-right: 60px;
        }
        &.active {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            position: relative;
            border-bottom: 3px solid #125cfb;
        }
    }
}
.forget-box {
    font-size: 14px;
    color: #125cfb;
    text-decoration: none;
    text-align: left;
    display: block;
    margin: 0px 0 20px;
    width: 60px;
}
.login-btn {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #125cfd;
    border-radius: 3px;
    font-size: 16px;
    margin-bottom: 10px;
    letter-spacing: 20px;
    text-indent: 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
        background: #094ee5;
    }
}
.wechat-box {
    width: 264px;
    margin: 0 auto;
    padding: 30px 0 0;
    text-align: center;
    .top-box {
        position: relative;
        border-top: 1px solid #ebebeb;
        .text {
            width: 50%;
            text-align: center;
            font-size: 12px;
            color: #cbcbcb;
            display: inline-block;
            padding: 0 20px;
            position: absolute;
            background-color: #fff;
            transform: translate(-50%, -50%);
        }
    }
    .imgs-box {
        width: 30px;
        cursor: pointer;
        padding: 20px 0 0;
        margin: 0 auto;
        img {
            width: 100%;
            height: auto;
        }
    }
}

// 重写el-form样式
.el-form-item {
    background: #ffffff;
    border: 1px solid #f1f2f7;
    margin-bottom: 16px;
    /deep/ .el-form-item__label {
        color: #333333;
        font-size: 14px;
        padding: 0 5px 0 0;
    }

    /deep/ .el-input__inner {
        border: none !important;
    }
    /deep/ .el-form-item__error {
        padding-left: 15px;
    }

    &.captcha-lines {
        /deep/ .el-input {
            width: calc(100% - 90px);
        }
        .captcha-box {
            width: 80px;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            .captcha-img {
                display: flex;
                align-items: center;
                height: 40px;
                width: 100%;
                cursor: pointer;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .captcha-txt {
                color: #125cfd;
                cursor: pointer;
                display: inline-block;
            }
            .captcha-time {
                color: #b6b8bd;
                display: inline-block;
            }
        }
    }
}
.account {
    /deep/ .el-form-item__error {
        padding-left: 0;
        margin-left: -50px;
    }
}
.mobile {
    /deep/ .el-form-item__error {
        padding-left: 0;
        margin-left: -77px;
    }
}
</style>
