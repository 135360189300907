<template>
  <div class="login-all-box">
    <div :class="['backgroud-box',  school_code == services.YNJD_CODE ? 'ynjdBg' :'']">
      <!-- 100051院校不显示分割线和平台名称，使用特殊类名控制LOGO尺寸 -->
      <div :class="['login-header-box',school_code == HZSFYQWY_CODE ?'large-logo':'']" v-if="school_code">
        <div class="school-logo">
          <img :src="schoolInfo && schoolInfo.school_logo" />
        </div>
        <template v-if="school_code!=HZSFYQWY_CODE">
          <div class="split-line"></div>
          <div class="page-name">统一登录认证</div>
        </template>
      </div>
      <div class="middle-con-box" ref="middleBox">
        <div class="middle-inner-box">
          <LoginBanner />

          <router-view />
        </div>
      </div>
    </div>
    <Footer :isFixed='isFooterFixed' />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LoginBanner from "@/components/LoginBanner.vue";
import { mapActions, mapState } from "vuex"
import services from '@/assets/js/services'
import '../../assets/css/loginMobile.less'

export default {
  name: 'loginLayout',
  components: { Footer, LoginBanner },
  data () {
    return {
      services,
      school_code: '',
      isFooterFixed: false,
      HZSFYQWY_CODE: 100051
    };
  },
  computed: {
    ...mapState({
      schoolInfo: (state) => state.login.schoolInfo,
    }),
  },
  mounted () {
    let that = this
    if (this.$route.params && this.$route.params.schoolcode && !isNaN(this.$route.params.schoolcode)) {
      localStorage.setItem("schoolCode", this.$route.params.schoolcode);
      this.school_code = this.$route.params.schoolcode
    }

    this.$nextTick(() => {
      that.setFooterHeight()
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
  },
  methods: {
    ...mapActions(["getLoginSchoolInfo", 'getImageCaptcha']),
    setFooterHeight () {
      let middleHeight = this.$refs.middleBox.offsetHeight;
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if ((hth - 180) > middleHeight) {
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 600px) {
    .backgroud-box .login-header-box.large-logo .school-logo img {
        width: 300px !important;
    }
}
.login-all-box {
    width: 100%;
    height: calc(100vh - 90px);
}
.backgroud-box {
    width: 100%;
    height: 100%;
    background: url('../../assets/imgs/login/bg-login.jpg') no-repeat center;
    background-size: 100% auto;
    &.ynjdBg {
        background: url('../../assets/imgs/login/bg-login-ynjd.jpg') no-repeat
            center;
        background-size: 100% auto;
    }
    .login-header-box {
        padding: 20px 0;
        text-align: left;
        display: flex;
        align-items: center;
        &.large-logo .school-logo img {
            max-width: none !important;
            max-height: none !important;
            width: 382px;
            height: auto;
        }
        .school-logo {
            vertical-align: top;
            display: inline-block;
            margin-left: 34px;
            img {
                max-height: 46px;
                width: auto;
                max-width: 200px;
            }
        }
        .split-line {
            width: 2px;
            height: 21px;
            background: #dee3eb;
            display: inline-block;
            margin: 0 20px 0;
            vertical-align: top;
        }
        .page-name {
            font-size: 20px;
            font-weight: bold;
            color: #393c44;
            line-height: 40px;
            display: inline-block;
            vertical-align: top;
            white-space: nowrap;
        }
    }
}

.middle-con-box {
    width: 100%;
    height: calc(100% - 89px);
    position: relative;
    min-height: 630px;
    .middle-inner-box {
        width: 972px;
        height: 560px;
        margin: 0 auto;
        position: absolute;
        transform: translate(-50%, -53%);
        top: 50%;
        left: 50%;
        .right-form-box {
            width: 412px;
            height: 560px;
            background: #ffffff;
            border-radius: 0px 3px 3px 0px;
            vertical-align: top;
            display: inline-block;
            padding: 0 74px;
            box-sizing: border-box;
        }
    }
}
@media only screen and (max-height: 780px) {
    .backgroud-box {
        height: auto;
    }
}
</style>