import Vue from 'vue'
import Vuex from 'vuex'
import modulesLogin from './login'
import modulesUser from './user'
import modulesManage from './manage'


//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  modules: {
    login: modulesLogin,
    user: modulesUser,
    manage: modulesManage,
  }
})

export default store