<template>
  <div>
    <div v-if="!utils.isMobile()" class="eol-header-box">
      <div :class="['eol-logo-box',school_code == HZSFYQWY_CODE?'large-logo':'']" v-if="school_code">
        <!-- 100051院校不显示分割线和平台名称，使用特殊类名控制LOGO尺寸 -->
        <img class="school-logo" :src="schoolInfo && schoolInfo.school_logo" />
        <template v-if="school_code != HZSFYQWY_CODE">
          <span class="split-line"></span>
          <div class="eol-system-name">信息服务中心</div>
        </template>
      </div>
      <ul class="eol-menu-box">
        <!-- 2024-12-31 100035/yxlx 唐钰莹将路由下线  -->
        <!-- <li v-if="school_code == specialCode" :class="$route.path.indexOf('/yxlx') > -1 ? 'menu-items active' : 'menu-items'">
          <a :href="`${services.XUEXI_SERVER}/center/${school_code}/yxlx`" target="_blank" class="jeol-shixun-href">研学旅行</a>
        </li> -->
        <li v-if="loginUserInfo && loginUserInfo.show_menu && loginUserInfo.show_menu.indexOf('school') > -1" class="menu-items" @click="goToSchool()">
          <a href="javascript:void(0)">学校主页</a>
        </li>
        <li :class="$route.path.indexOf('/worker') > -1 ? 'menu-items active' : 'menu-items'">
          <router-link class="jeol-shixun-href" :to="`/${school_code}/worker`">工作台</router-link>
        </li>
        <li v-if="loginUserInfo && loginUserInfo.show_menu && loginUserInfo.show_menu.indexOf('xuexi') > -1" class="menu-items">
          <a class="jeol-shixun-href" :href="`${services.XUEXI_SERVER}/center/${school_code}/user/center`" target="_blank">学习中心</a>
        </li>
        <li v-if="loginUserInfo && loginUserInfo.user_type == 2" :class="$route.path.indexOf('/userManage') > -1 ? 'menu-items active' : 'menu-items'">
          <router-link class="jeol-shixun-href" :to="`/${school_code}/userManage`">用户管理</router-link>
        </li>
      </ul>
      <div :class="['eol-right-user', menuOpen ? 'up' : 'down']" @mouseleave="menuOpen = false" @click="menuOpen = !menuOpen">
        <div class="mine-lines">
          <div class="user-imgs-box"></div>
          <span>{{loginUserInfo.name ? loginUserInfo.name : loginUserInfo.username}}</span>
        </div>
        <ul @mouseleave="menuOpen = false" class="eol-submine-box">
          <li class="submine-items">
            <router-link :to="`/${school_code}/worker`">信息服务中心</router-link>
          </li>
          <li class="submine-items">
            <router-link :to="`/${school_code}/userInfo`">个人信息</router-link>
          </li>
          <li class="submine-items">
            <router-link :to="`/${school_code}/editPassword`">修改密码</router-link>
          </li>
          <li class="submine-items" @click="logoutEvents">
            <a href="javascript:;">退出登录</a>
          </li>
        </ul>
      </div>
    </div>

    <div v-else class="eol-header-box-h5">
      <div :class="['eol-logo-box',school_code == HZSFYQWY_CODE?'large-logo':'']" v-if="school_code">
        <!-- 100051院校不显示分割线和平台名称，使用特殊类名控制LOGO尺寸 -->
        <img class="school-logo" :src="schoolInfo && schoolInfo.school_logo" />
        <template v-if="school_code != HZSFYQWY_CODE">
          <span class="split-line"></span>
          <div class="eol-system-name">信息服务中心</div>
        </template>
      </div>
      <div class="eol-menu-box-h5">
        <img src="../assets/imgs/header/icons-menu.png" @click="H5MenuOpen = !H5MenuOpen">
        <el-drawer :visible.sync="H5MenuOpen" direction="rtl" custom-class="el-drawer-menu" :show-close=false :withHeader="false">
          <div class="user-info-box">
            <div class="user-imgs-box"></div>
            <span>{{loginUserInfo.name ? loginUserInfo.name : loginUserInfo.username}}</span>
          </div>
          <ul class="eol-menu-list">
            <li class="menu-items">
              <a :href="`/${school_code}/worker`" target="_self">信息服务中心</a>
            </li>
            <li class="menu-items" v-if="loginUserInfo && loginUserInfo.show_menu && loginUserInfo.show_menu.indexOf('xuexi') > -1">
              <a :href=" `${services.XUEXI_SERVER}/center/${school_code}/user/center`" target="_self">学习中心</a>
            </li>
            <!-- <li :class="$route.path.indexOf('/yxlx') > -1 ? 'menu-items active' : 'menu-items'" v-if="school_code == specialCode">
              <a :href="`${services.XUEXI_SERVER}/center/${school_code}/yxlx`" target="_self">研学旅行</a>
            </li> -->

            <!-- <li :class="$route.path.indexOf('/userManage') > -1 ? 'menu-items active' : 'menu-items'" v-if="loginUserInfo && loginUserInfo.user_type == 2">
              <router-link :to="`/${school_code}/userManage`">用户管理</router-link>
            </li> -->

            <li class="menu-items">
              <router-link :to="`/${school_code}/userInfo`">个人信息</router-link>
            </li>
            <li class="menu-items">
              <router-link :to="`/${school_code}/editPassword`">修改密码</router-link>
            </li>
            <li class="menu-items logout" @click="logoutEvents">
              <a href="javascript:;">退出登录</a>
            </li>
          </ul>
        </el-drawer>
      </div>
    </div>

  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import services from '@/assets/js/services'
import utils from "@/assets/js/utils.js"
export default {
  name: 'Footer',
  components: {},
  data () {
    return {
      services,
      menuOpen: false,
      H5MenuOpen: false,
      school_code: '',
      specialCode: '',
      utils,
      HZSFYQWY_CODE: 100051
    };
  },
  computed: {
    ...mapState({
      loginUserInfo: (state) => state.login.loginUserInfo,
      schoolInfo: (state) => state.login.schoolInfo,
    })
  },
  created () {
    this.methodLoginUserInfo()
    let code = localStorage.getItem('schoolCode')
    if (code) {
      this.getLoginSchoolInfo({ school_code: code });
      this.school_code = code;
    }
    this.judgeSchool()
  },
  methods: {
    ...mapActions(["requestLogout", 'getLoginUserInfo', "getLoginSchoolInfo"]),
    // 退出登录
    logoutEvents () {
      let that = this
      this.requestLogout().then(res => {
        if (res.code == '0000') {
          let code = localStorage.getItem('schoolCode')
          if (!res.data || !res.data.redirect) {
            if (code) {
              that.$router.push({ path: `/${code}/login` })
            } else {
              that.$router.push({ path: '/login' })
            }
          }
        }
      })
    },
    // 获取用户信息
    methodLoginUserInfo () {
      let that = this
      that.getLoginUserInfo({ is_append: 1 })
    },
    // 去学校主页
    goToSchool () {
      let code = localStorage.getItem('schoolCode')
      if (code) {
        window.open(`${services.JIAOYU_SCHOOL}/school/${code}`, '_blank')
      }
    },
    judgeSchool () {
      if (process.env.NODE_ENV == 'development') {
        this.specialCode = '100064'
      } else {
        if (process.env.VUE_APP_FLAG === 'pro') {
          // 正式环境 
          this.specialCode = '100035'
        } else {
          // 测试环境 
          this.specialCode = '100064'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/* header --- start */
.eol-header-box {
    min-width: 1200px;
    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 23, 73, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}

.eol-header-box .eol-logo-box {
    height: 34px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 20px;
    &.large-logo {
        overflow: unset;
        .school-logo {
            max-width: none;
            max-height: none;
            height: 36px;
        }
    }
}

.eol-header-box .eol-logo-box .split-line {
    width: 1px;
    height: 17px;
    background: #c0c1cc;
    margin: 0 15px;
    display: inline-block;
}

.eol-header-box .eol-logo-box .school-logo {
    width: auto;
    height: 100%;
    max-height: 34px;
    max-width: 200px;
}

.eol-header-box .eol-logo-box .eol-system-name {
    font-size: 16px;
    font-weight: bold;
    color: #464748;
    display: inline-block;
    vertical-align: middle;
}

.eol-header-box .eol-menu-box {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    flex: 1;
}

.eol-header-box .menu-items {
    display: inline-block;
    height: 70px;
    line-height: 70px;
    vertical-align: top;
    font-size: 16px;
    font-weight: bold;
    color: #63676e;
    text-align: center;
    width: 16%;
    position: relative;
}

.eol-header-box .menu-items a {
    font-size: 16px;
    font-weight: bold;
    color: #63676e;
    display: block;
    cursor: pointer;
    text-align: center;
}

.eol-header-box .menu-items a:hover {
    color: #125cfb;
    text-decoration: none;
}

.eol-header-box .menu-items.active a {
    color: #125cfb;
}
.eol-header-box .menu-items.active {
    background: #e9f0ff;
    box-shadow: 0px 0px 8px 0px rgba(0, 23, 73, 0.06);
}
.eol-header-box .menu-items.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #125cfd;
}
.eol-header-box .eol-right-user {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    max-width: 260px;
    position: relative;
}

.eol-header-box .eol-right-user .close-page-box {
    position: absolute;
    display: inline-block;
    font-size: 16px;
    color: #3a3a3a;
    display: inline-block;
    margin-left: 5px;
    top: 13px;
    left: -80px;
    cursor: pointer;
}

.eol-header-box .eol-right-user .close-page-box img {
    vertical-align: -4px;
}

.eol-header-box .eol-right-user .mine-lines {
    height: 70px;
    line-height: 70px;
    font-size: 14px;
    font-weight: 400;
    color: #125cfb;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.eol-header-box .eol-right-user .mine-lines span {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 200px;
    margin: 0 16px 0 6px;
}

.eol-header-box .eol-right-user .mine-lines::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url('../assets/imgs/header/icons-jiantou-down.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-left: 4px;
}

.eol-header-box .eol-right-user.up .mine-lines::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
}

.eol-header-box .eol-right-user.down .mine-lines::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
}

.eol-header-box .eol-right-user .user-imgs-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    vertical-align: middle;
    text-align: right;
    background: url('../assets/imgs/header/mine-icons.png') no-repeat center;
    background-size: 100% auto;
}

.eol-header-box .eol-right-user .eol-submine-box {
    background: #ffffff;
    border: 1px solid #dfe3e4;
    position: absolute;
    z-index: 1000;
    top: 70px;
    right: -10px;
    padding: 18px 25px 18px 20px;
    text-align: left;
    display: none;
    min-width: 90px;
}

.eol-header-box .eol-right-user.up .eol-submine-box {
    display: block;
}

.eol-header-box .eol-right-user.down .eol-submine-box {
    display: none;
}

.eol-header-box .eol-right-user .submine-items {
    height: 30px;
    color: #71737b;
    line-height: 30px;
    font-size: 14px;
    cursor: pointer;
}
.eol-header-box .eol-right-user .submine-items a {
    display: block;
    width: auto;
    color: #71737b;
}

.eol-header-box .eol-right-user .submine-items.active,
.eol-header-box .eol-right-user .submine-items:hover a {
    color: #3460e4;
    text-decoration: none;
}

.eol-header-box .eol-right-user .mine-left-icons {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: -2px;
}

@media screen and (max-width: 1440px) {
    .eol-header-box .eol-logo-box .school-logo {
        max-height: 25px;
    }
}

/* header ---- 结束 */
.eol-header-box-h5 {
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 10px;
    position: relative;
    z-index: 2050;
    .eol-logo-box {
        display: flex;
        align-items: center;
        &.large-logo {
            .school-logo {
                max-width: none;
                max-height: none;
                width: 300px;
            }
        }
        .school-logo {
            width: auto;
            height: 100%;
            max-height: 34px;
            max-width: 150px;
        }
        .split-line {
            width: 1px;
            height: 17px;
            margin: 0 12px;
            display: inline-block;
            background: #c0c1cc;
        }
        .eol-system-name {
            font-weight: bold;
            color: #464748;
            display: inline-block;
            vertical-align: middle;
            font-weight: bold;
            font-size: 14px;
        }
    }
    .eol-menu-box-h5 {
        img {
            width: 18px;
            height: auto;
        }
        ::v-deep .el-drawer-menu {
            width: 228px !important;
            top: 70px !important;
            background: transparent !important;
            // box-shadow: 5px -1px 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: none;
            max-height: calc(100vh - 70px);
            .user-info-box {
                background: #fff;
                border-top: 1px solid #d6d8da;
                border-bottom: 1px solid #d6d8da;
                padding: 19px 20px;
                .user-imgs-box {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;
                    background: url('../assets/imgs/header/mine-icons-h5.png')
                        no-repeat center;
                    background-size: 100% auto;
                    vertical-align: -4px;
                }
                span {
                    font-size: 14px;
                    color: #626467;
                }
            }
            .eol-menu-list {
                background: #fff;
                padding: 20px 0 26px;
                .menu-items {
                    text-align: center;
                    padding: 9.5px 0;
                    a {
                        font-size: 14px;
                        color: #71737b;
                    }
                    &.logout {
                        padding: 0;
                        width: 186px;
                        height: 34px;
                        background: #ffffff;
                        border: 1px solid #d6d8da;
                        line-height: 34px;
                        margin: 14px auto 0;
                    }
                }
            }
        }
    }
}
</style>
