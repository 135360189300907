// import VueCookies from 'vue-cookies';

const redirect = (e, path, _blank) => {
  if (_blank) {
    let routeUrl = e.$router.resolve({
      path: path
    });
    window.open(routeUrl.href, '_blank');
  } else {
    e.$router.push({ path: path })
  }
}

// 动态加载js
const loadScript = (url, callback) => {
  if (typeof document !== "undefined") {
    var script = document.createElement("script");
  }
  script.type = "text/javascript";
  if (typeof callback !== "undefined") {
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        callback();
      };
    }
  }
  script.src = url;
  if (typeof document !== "undefined") {
    document.head.appendChild(script);
  }
}

const loadStatistics = function () {
  if (typeof document !== "undefined") {
    // 2022-7-26 cnzz收费弃用
    // var script1 = document.createElement("script");
    // script1.type = "text/javascript";
    // script1.src = "https://v1.cnzz.com/z_stat.php?id=1280977381&web_id=1280977381";
    // var outer = document.createElement("div");
    // outer.style.height = '1px';
    // outer.style.width = '1px';
    // outer.style.overflow = 'hidden';
    // outer.appendChild(script1);

    var script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://hm.baidu.com/hm.js?f65b083993ae169cb62ec9bd2870bc75";

    // document.body.append(outer);
    document.body.append(script2);
  }
}

// 设置标题
const setTitle = (title) => {
  if (title) {
    title = '信息服务中心-中国教育在线'
  } else {
    title = '信息服务中心-中国教育在线'
  }
  document.title = title
}

/**
 * 构造树型结构数据
 * @param {*} source 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
const transTreeData = (source, id, parentId, children, rootId) => {
  id = id || 'id'
  parentId = parentId || 'parentId'
  children = children || 'children'
  rootId = rootId || 0
  const cloneData = JSON.parse(JSON.stringify(source))// 对源数据深度克隆
  return cloneData.filter(father => {
    const branchArr = cloneData.filter(child => father[id] == child[parentId])// 返回每一项的子级数组
    branchArr.length > 0 ? father[children] = branchArr : delete father[children]// 如果存在子级，则给父级添加一个children属性，并赋值
    return father[parentId] == rootId // 返回第一层
  })
}

// 判断是否是手机端访问
const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

// 判断是否是谷歌浏览器
const bowerUserAgent = () => {
  var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
  let isChrome = false
  if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") == -1) {
    isChrome = true
  } else {
    isChrome = false
  }
  return isChrome
}

// localStorage方法
const setUserInfo = val => localStorage.setItem('userInfo', JSON.stringify(val));
const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo'));
};
const removeUserInfo = () => localStorage.removeItem('userInfo');
const clearLocal = () => localStorage.clear();

// 需要校验登录状态的路由
const whiteList = ["/worker", "/userInfo", "/editPassword", "/newPassword", "/userManage", "/userManage/userList", "/userManage/application", "/userManage/operationLog"];

// 判断路由是否包含有whiteList
const pathRouteName = (path) => {
  var isName = false
  for (var i = 0; i < whiteList.length; i++) {
    let str = path.replace(/(\/\d+)/g, "");
    if (whiteList[i].indexOf(str) > -1) {
      isName = true
      break;
    }
  }
  return isName
}

const getUrlSchoolCode = (url) => {
  let code = ''
  if (url) {
    let arr = url.split("/");
    code = arr[1]
  }
  return code
}

export default {
  redirect,
  loadScript,
  setTitle,
  loadStatistics,
  transTreeData,
  isMobile,
  whiteList,
  getUrlSchoolCode,
  pathRouteName,
  bowerUserAgent,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  clearLocal,
}
