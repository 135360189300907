<template>
  <div class="editPassword-all-box">
    <Header />
    <div class="editPassword-middle-box" >
      <div class="editPassword-inner-box" ref="middleBox">
        <div class="inner-label-box">
          <div class="label-name">修改密码</div>
        </div>
        <div class="editPassword-form">
          <el-form ref="newForm" :model="form" :rules="formRules" label-width="90px">
            <el-form-item prop="old_password" :error="formErrors.old_password">
              <label slot="label">原&nbsp;&nbsp;&nbsp;密&nbsp;&nbsp;&nbsp;&nbsp;码</label>
              <el-input type="password" v-model="form.old_password"  placeholder="请输入原密码"></el-input>
            </el-form-item>
            <el-form-item prop="password" :error="formErrors.password">
              <label slot="label">设置新密码</label>
              <el-input @paste.native.capture.prevent="handleFalse" v-model="form.password"  type="password" autocomplete="off" placeholder="8-16位字符，必须包含字母大小写、数字"></el-input>
            </el-form-item>
            <el-form-item prop="password_confirmation" :error="formErrors.password_confirmation">
              <label slot="label">确认新密码</label>
              <el-input @paste.native.capture.prevent="handleFalse" v-model="form.password_confirmation" type="password" autocomplete="off" placeholder="请再次输入新密码"></el-input>
            </el-form-item>
          </el-form>
          <div class="login-btn" @click="submitForm()">确认</div>
        </div>
      </div>
    </div>
    <Footer :isFixed='isFooterFixed' />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import { mapActions } from "vuex"

export default {
  name: 'EditPassword',
  components: {Footer, Header},
   data() {
    let validateOldPass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入原密码'));
			} else {
				const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
				if(!reg.test(value)){
					callback(new Error('密码为[8-16]位字母大小写及数字组合'));
				} else {
					callback();
				}
			}
		};
    let validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入新密码'));
			} else {
				const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
				if(!reg.test(value)){
					callback(new Error('密码为[8-16]位字母大小写及数字组合'));
				} else {
					callback();
				}
			}
		};
		let validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入新密码'));
			} else if (value !== this.form.password) {
				callback(new Error("两次输入的密码不相同"));
			} else {
				callback();
			}
		};
    return {
      shoolCode: localStorage.getItem('schoolCode'),
      form: {
        password: '',
        password_confirmation: '',
        _method: 'put',
        old_password: '',
      },
      formRules: {
        old_password: [{ validator: validateOldPass, trigger: ['blur','change'] }],
        password: [{ validator: validatePass, trigger: ['blur','change'] }],
        password_confirmation: [{ validator: validatePass2, trigger: ['blur','change'] }],
      },
      formErrors: {
        password: '',
        password_confirmation: '',
        old_password: '',
      },
      isFooterFixed: false,
    };
  },
  mounted(){
    let that = this
    this.$nextTick(() => {
      that.setFooterHeight()
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
  },
  methods: {
    ...mapActions(["requestEditPassword"]),
    setFooterHeight(){
      let middleHeight = this.$refs.middleBox.offsetHeight; 
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if((hth - 160) > middleHeight){
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
    },
    handleFalse() {
      // 终止
      return false;
    },
    backLogin(){
      this.$router.go(-1);
    },
     // 提交表单事件
    submitForm() {
      let that = this;
      that.$refs['newForm'].validate((valid) => {
        if (valid) {
          that.requestEditPassword({...that.form}).then((res) => {
            if(res.code == '0000'){
              that.$successMsg(res.message ? res.message : "修改成功");
              that.$router.push({path: `/${that.shoolCode}/worker`})
            } else {
              that.$errorMsg(res.message ? res.message : "修改失败")
            }
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.editPassword-all-box {
  width: 100%;
  background: #F7F8F9;
  position: relative;
  height: 100%;
  min-height: 800px;
  .editPassword-middle-box {
    position: relative;
    height: calc(100% - 160px);
    min-height: 640px;
    .editPassword-inner-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      width: 1100px;
      height: 600px;
      background: #fff;
      border-radius: 5px;
      .inner-label-box {
        border-bottom: 1px solid #F3F3F3;
        padding: 15px 25px 15px;
        text-align: left;
        .label-name {
          font-weight: bold;
          color: #4C4E51;
          font-size: 18px;
          display: inline-block;
          &::before {
            content: "";
            display: inline-block;
            width: 13px;
            height: 14px;
            background: url("../assets/imgs/login/editPassword.png") no-repeat center;
            background-size: 100% auto;
            vertical-align: -1px;
            margin-right: 14px;
          }
        }
      }
      .editPassword-form {
        width: 380px;
        margin: 0 auto;
        display: block;
        padding: 60px 0 0;
        .login-btn {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #125CFD;
          border-radius: 3px;
          font-size: 14px;
          margin-top: 110px;
          letter-spacing: 20px;
          text-indent: 20px;
          color: #FFFFFF;
          cursor: pointer;
          &:hover {
            background: #094EE5;
          }
        }
      }
    }
  }
}
// 适配h5
@media screen and (max-width: 600px) {
  .editPassword-all-box {
    /deep/ .editPassword-inner-box {
      width: 100vw !important;
      .inner-label-box {
        padding: 20px !important;
        .label-name {
          font-size: 16px !important;
          &::before {
            margin-right: 6px !important;
          }
        }
      }
      .editPassword-form {
        width: 100% !important;
        padding: 30px 40px 0 !important;
        box-sizing: border-box !important;
      }
    }
  }
}
// 重写el-form样式
.el-form-item {
  background: #FFFFFF;
  border: 1px solid #F1F2F7;
  /deep/ .el-form-item__label {
    color: #333333;
    font-size: 14px;
    padding: 0 5px 0 0;
  }
  
  /deep/ .el-input__inner {
    border: none !important;
  }
  /deep/ .el-form-item__error {
    padding-left: 15px;
  }
}
</style>