<template>
  <div>
    <vue-base-dialog
      :dialogConfig="dialogConfig"
      class="selfCreateDialog"
      ref="childDialog"
      @close="parentModalFunc"
    >
      <div class="dialog-content">
        <!-- 绑定新手机号 -->
        <div class="center-form-box">
          <div class="mess-top">用户<strong>（{{dialogConfig.data.name}}）</strong>的密码已经过重置为：</div>
          <div class="passString-box">
            <div class="left-string">{{dialogConfig.data.password}}</div>
            <div class="copy-btn" @click="copyPassText()">复制</div>
          </div>
          <div class="bottom-line-btn">
            <div class="cancel-btn" @click="cancelModal">取消</div>
            <div class="right-btn" @click="cancelModal">确定</div>
          </div>
        </div>
      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
    form: {}
  },
  components: {
    VueBaseDialog,
  },
  data() {
    return {
      
    };
  },
  mounted() {},
  methods: {
    parentModalFunc() {},
    cancelModal(){
      this.$emit('cancleDialog');
    },
    copyPassText() {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = this.dialogConfig.data.password;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      this.$successMsg("复制成功");
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    }
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 10px 0;
}
.top-tips {
  width: 400px;
  height: 34px;
  background: #FFFCF8;
  font-size: 12px;
  line-height: 34px;
  color: #EF7D47;
  margin-bottom: 30px;
}
.center-form-box {
  width: 400px;
  padding: 0 0 20px;
  .mess-top {
    font-size: 14px;
    color: #333;
    margin-bottom: 14px;
  }
  .passString-box {
    width: 260px;
    margin:  0 auto;
    background: #fef1f7;
    height: 32px;
    line-height: 32px;
    text-align: center;
    display: flex;
    .left-string {
      flex: 1;
      font-size: 18px;
      display: inline-block;
      font-weight: bold;
      color: #FF8E36;
    }
    .copy-btn {
      width: 67px;
      background: #ffebdc;
      color: #FF8E36;
      display: inline-block;
      cursor: pointer;
      &:hover {
        background: #ffe2cc;
      }
    }
  }
  .bottom-line-btn {
    width: 100%;
    text-align: center;
    padding: 32px 0 20px;
    div {
      width: 102px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
    }
    .right-btn {
      background: #F2F6FF;
      border: 1px solid #2462F5;
      color: #125cfd;
      &:hover {
        background: #FFFFFF;
      }
    }
    .cancel-btn {
      color: #333333;
      background: #F8F8F8;
      border: 1px solid #DBDBDB;
      margin-right: 50px;
      &:hover {
       background: #FFFFFF;
      }
    }
  }
 
}
</style>