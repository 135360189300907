<template>
  <div class="userinfo-all-box">
    <Header />

    <component 
    :is="dialogConfig.name" 
    :dialogConfig="dialogConfig" 
    @cancleDialog="cancleDialog(arguments)"
		@refreshData="refreshInfo"
    ></component>
    
    <div class="userinfo-middle-box" ref="middleBox">
      <div class="userinfo-inner-box">
        <div class="inner-label-box">
          <div class="label-name">个人信息</div>
        </div>
        <div class="userinfo-contnet-box">
          <div class="info-lines">
            <div class="left-label">用户名称</div>
            <div :class="loginUserInfo.name ? 'status-icon active' : 'status-icon'"></div>
            <div class="con-text">{{loginUserInfo.name}}</div>
          </div>
          <div class="info-lines">
            <div class="left-label">用户账号</div>
            <div :class="loginUserInfo.username ? 'status-icon active' : 'status-icon'"></div>
            <div class="con-text">{{loginUserInfo.username}}</div>
          </div>
          <div class="info-lines">
            <div class="left-label">手&nbsp;&nbsp;机&nbsp;&nbsp;号</div>
            <div :class="loginUserInfo.mobile ? 'status-icon active' : 'status-icon'"></div>
            <div class="con-text">{{loginUserInfo.mobile}}</div>
            <div class="edit-btn" @click="changePhoneMethod()">{{loginUserInfo.mobile ? '换绑手机号' : "绑定手机号"}}</div>
          </div>
          <!-- <div class="info-lines">
            <div class="left-label">微&nbsp;&nbsp;信&nbsp;&nbsp;号</div>
            <div :class="loginUserInfo.user_open_data && loginUserInfo.user_open_data.weixin && loginUserInfo.user_open_data.weixin.nickname ? 'status-icon active' : 'status-icon'"></div>
            <div v-if="loginUserInfo.user_open_data && loginUserInfo.user_open_data.weixin && loginUserInfo.user_open_data.weixin.nickname" class="con-text">{{loginUserInfo.user_open_data.weixin.nickname }}</div>
            <div v-if="loginUserInfo.user_open_data && loginUserInfo.user_open_data.weixin && loginUserInfo.user_open_data.weixin.nickname" @click="changeWchatUnbind()" class="edit-btn" >解绑微信</div>
            <div v-else class="edit-btn" @click="changeWchatBind()">绑定微信</div>
          </div> -->
          <div class="info-lines">
            <div class="left-label">邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱</div>
            <div :class="loginUserInfo.email ? 'status-icon active' : 'status-icon'"></div>
            <div class="con-text">{{loginUserInfo.email}}</div>
          </div>
          <div class="info-lines">
            <div class="left-label">所在院校</div>
            <div :class="loginUserInfo.school_name ? 'status-icon active' : 'status-icon'"></div>
            <div class="con-text"><span>{{loginUserInfo.school_name}}</span></div>
          </div>
          <div class="edit-info-btn" @click="changeEditInfoModal">修改</div>
        </div>
      </div>
    </div>
    <Footer :isFixed='isFooterFixed' />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import { mapState, mapActions } from "vuex";
import services from '@/assets/js/services'
export default {
  name: 'UserInfo',
  components: {
    Footer, 
    Header,
		changePhoneModal:() => import("./changePhoneModal.vue"),
		bindPhoneModal:() => import("./bindPhoneModal.vue"),
		editInfoModal:() => import("./editInfoModal.vue"),
  },
  data() {
    return {
      services,
      isFooterFixed: false,
      dialogConfig: {
				name: '',
				title: '',
				data: null,
				dialogVisible: true,
			},
    };
  },
  computed: {
    ...mapState({ 
      loginUserInfo: (state) => state.login.loginUserInfo,
    }),
  },
  mounted(){
    // this.refreshInfo()
    let that = this
    this.$nextTick(() => {
      that.setFooterHeight()
      window.onresize = () => {
        that.setFooterHeight()
      }
    })
  },
  methods: {
    ...mapActions(["getLoginUserInfo", 'requestWechatUnbind']),
    setFooterHeight(){
      let middleHeight = this.$refs.middleBox.offsetHeight; 
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      let hth = document.documentElement.clientHeight;
      if((hth - 160) > middleHeight){
        this.isFooterFixed = true
      } else {
        this.isFooterFixed = false
      }
    },
    // 点击修改个人信息
    changeEditInfoModal (){
      this.dialogConfig.data  = this.loginUserInfo;
      this.dialogConfig.name  = 'editInfoModal';
			this.dialogConfig.title = "修改个人信息";
    },
    //关闭弹框
		cancleDialog(){
			this.dialogConfig.name = null;
		},
    // 手机号修改
		changePhoneMethod(){
			this.dialogConfig.data  = this.loginUserInfo;
      if(this.loginUserInfo.mobile){
        this.dialogConfig.name  = 'changePhoneModal';
        this.dialogConfig.title = "换绑手机号";
      } else {
        this.dialogConfig.name  = 'bindPhoneModal';
        this.dialogConfig.title = "绑定手机号";
      }
		},
    // 刷新页面
		refreshInfo(){
      let that = this
      that.$openLoading()
			that.getLoginUserInfo({is_append: 1}).then(() => {
        that.$closeLoading()
      })
		},
    // 解绑微信方法
    changeWchatUnbind() {
      let that = this
      that.$confirm(`您确定要解绑微信吗？`, '解绑微信', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        iconClass: 'noneConfirmIcon',
        cancelButtonClass: 'cancelConfirmBtn',
        confirmButtonClass: 'rightConfirmBtn',
        dangerouslyUseHTMLString: true
      }).then(() => {
        that.$openLoading()
        that.requestWechatUnbind({
          _method: 'put',
          type: 'weixin'
        }).then(res => {
          that.$closeLoading()
          if(res.code == '0000'){
            that.refreshInfo()
            that.$successMsg(res.message ? res.message : "操作成功");
          } else {
            that.$errorMsg(res.message ? res.message : "操作失败")
          }
        })
      }).catch(() => { })
    },
    // 绑定微信方法
    changeWchatBind() {
      if (process.env.NODE_ENV == 'development') {
        // 开发环境测试
        window.open('http://jw.jiaoyu.cn' + "/api/weixin/binding?back_url=" + window.location.href, "_self")
      } else {
        // 生成环境
        window.open(services.HOST + "/api/weixin/binding?back_url=" + window.location.href, "_self")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.userinfo-all-box {
  width: 100%;
  background: #F7F8F9;
  position: relative;
  height: 100%;
  min-height: 800px;
  .userinfo-middle-box {
    position: relative;
    height: calc(100% - 160px);
    min-height: 640px;
    .userinfo-inner-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      width: 1100px;
      height: 600px;
      background: #fff;
      border-radius: 5px;
      .inner-label-box {
        border-bottom: 1px solid #F3F3F3;
        padding: 15px 25px 15px;
        text-align: left;
        .label-name {
          font-weight: bold;
          color: #4C4E51;
          font-size: 18px;
          display: inline-block;
          &::before {
            content: "";
            display: inline-block;
            width: 13px;
            height: 14px;
            background: url("../../assets/imgs/login/userInfo.png") no-repeat center;
            background-size: 100% auto;
            vertical-align: -1px;
            margin-right: 14px;
          }
        }
      }
      .userinfo-contnet-box {
        width: 480px;
        padding: 67px 0 0;
        margin: 0 auto;
        line-height: 20px;
        .info-lines {
          border-bottom: 1px solid #F1F2F7;
          padding-bottom: 20px;
          margin-bottom: 20px;
          text-align: left;
          .left-label {
            color: #333333;
            display: inline-block;
            font-size: 14px;
            width: 56px;
          }
          .status-icon {
            width: 13px;
            display: inline-block;
            height: 13px;
            background: url("../../assets/imgs/login/gray-right.png") no-repeat center;
            background-size: 100% auto;
            margin: 0 10px 0 20px;
            vertical-align: -1px;
            &.active {
              background: url("../../assets/imgs/login/green-right.png") no-repeat center;
              background-size: 100% auto;
            }
          }
          .con-text {
            color: #333333;
            display: inline-block;
            font-size: 14px;
            vertical-align: top;  
          }
          .edit-btn {
            color: #125CFD;
            display: inline-block;
            font-size: 14px;
            float: right;
            cursor: pointer;
          }
        }
        .edit-info-btn {
          width: 240px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #125CFD;
          border-radius: 3px;
          font-size: 14px;
          margin: 35px auto 0;
          letter-spacing: 20px;
          text-indent: 20px;
          color: #FFFFFF;
          cursor: pointer;
          &:hover {
            background: #094EE5;
          }
        }
      }
    }
  }
}

// 适配h5
@media screen and (max-width: 600px) {
  .userinfo-all-box {
    /deep/ .userinfo-inner-box {
      width: 100vw !important;
      .inner-label-box {
        padding: 20px !important;
        .label-name {
          font-size: 16px !important;
          &::before {
            margin-right: 6px !important;
          }
        }
      }
      .userinfo-contnet-box {
        width: 100% !important;
        padding: 30px 40px 0 !important;
        box-sizing: border-box !important;
      }
    }
  }
}
</style>