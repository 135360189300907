<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import services from '@/assets/js/services'
export default {
  name: "App",
  data() {
    return {
      services,
    }
  },
  mounted() {
    let code = utils.getUrlSchoolCode(window.location.pathname)
    if(code){
      localStorage.setItem("schoolCode", code);
    } else {
      localStorage.setItem("schoolCode", '');
      window.open(services.JIAOYU_CN, "_self")
    }
    if (process.env.NODE_ENV == 'production') {
      // 第二层判断打包后是测试环境还是正式环境
      if(process.env.VUE_APP_FLAG === 'pro'){
        utils.loadStatistics();
      }
    }
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  min-height: 100%;
  background: #f7f8f9;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
