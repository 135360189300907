import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies';
import utils from '../assets/js/utils.js';
import store from '@/store';

import LoginLayout from '../views/Login/loginLayout.vue'
import LoginIndex from '../views/Login/login.vue'
import ResetPassword from '../views/Login/resetPassword.vue'
import NewPassword from '../views/Login/newPassword.vue'
import Worker from '../views/Worker/index.vue'
import UserInfo from '../views/UserInfo/index.vue'
import EditPassword from '../views/EditPassword.vue'
import UserManageLayout from '../views/UserManage/index.vue'
import Application from '../views/UserManage/application.vue'
import OperationLog from '../views/UserManage/operationLog.vue'
// import Administrators from '../views/UserManage/administrators.vue'
import UserList from '../views/UserManage/userList.vue'
import WechatSuccess from '../views/Wechat/success.vue'
import WechatError from '../views/Wechat/error.vue'
import Page404 from '../views/Page404.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 2022-8-22 页面路由需要添加/schoolcode
const routes = [
  {
    path: '/',
    name: 'Redirect',
    redirect: '/:schoolcode(\\d+)?/login',
  },
  {
    path: '/:schoolcode(\\d+)?/login',
    component: LoginLayout,
    title: '登录',
    children: [
      {
        path: '/',
        name: 'LoginIndex',
        component: LoginIndex
      },
      {
        path: '/:schoolcode(\\d+)?/resetPassword',
        name: 'ResetPassword',
        component: ResetPassword
      },
      {
        path: '/:schoolcode(\\d+)?/newPassword',
        name: 'NewPassword',
        component: NewPassword
      },
    ]
  },
  {
    path: '/:schoolcode(\\d+)?/weSuccess',
    name: 'WechatSuccess',
    component: WechatSuccess
  },
  {
    path: '/:schoolcode(\\d+)?/weError',
    name: 'WechatError',
    component: WechatError
  },
  {
    path: '/:schoolcode(\\d+)?/worker',
    name: 'Worker',
    component: Worker,
  },
  {
    path: '/:schoolcode(\\d+)?/userInfo',
    name: 'UserInfo',
    component: UserInfo,
  },
  {
    path: '/:schoolcode(\\d+)?/editPassword',
    name: 'EditPassword',
    component: EditPassword,
  },
  {
    path: '/:schoolcode(\\d+)?/userManage',
    name: 'UserManageLayout',
    component: UserManageLayout,
    redirect: '/:schoolcode(\\d+)?/userManage/userList',
    children: [
      {
        path: '/:schoolcode(\\d+)?/userManage/userList',
        name: 'UserList',
        component: UserList
      },
      {
        path: '/:schoolcode(\\d+)?/userManage/application',
        name: 'Application',
        component: Application
      },
      {
        path: '/:schoolcode(\\d+)?/userManage/operationLog',
        name: 'OperationLog',
        component: OperationLog
      },
      // {
      //   path: '/userManage/administrators',
      //   name: 'Administrators',
      //   component: Administrators
      // },
    ]
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let code = to.params && to.params.schoolcode ? to.params.schoolcode : ''
  let appsToken = VueCookies.get("api_token");
  let isNeedLogin = utils.pathRouteName(to.path)
  if (isNeedLogin) {
    if (appsToken) {
      store.dispatch("getLoginUserInfo", {is_append: 1, loginLink: true}).then(res => {
        if(res.code == '0000'){
          if(res.data) {
            if(code == res.data.school_code){
              if(res.data.pwd_force == 1) {
                // 拦截强制修改密码
                if(to.path.indexOf("/newPassword") > -1){
                  next()
                } else{
                  next(`/${code}/newPassword`)
                }
              } else if(res.data.user_type == 1){
                // 拦截是否有用户管理权限
                if(to.path.indexOf('/userManage') > -1){
                  next(`/${code}/worker`)
                } else {
                  next()
                }
              } else {
                next()
              }
            } else {
              if(to.path.indexOf("/login") > -1){
                next()
              } else{
                next(`/${code}/login`)
              }
            }
          }
        } else if(res.code == '2001'){
          if(to.path.indexOf("/login") > -1){
            next()
          } else{
            next(`/${code}/login`)
          }
        }
      })
    } else {
      next()
    }
  } else {
    if (to.path.indexOf("/login") > -1) {
      if(appsToken){
        store.dispatch("getLoginUserInfo", {is_append: 1, loginLink: true}).then(res => {
          if(res.code == '0000'){
            if(code == res.data.school_code){
              // 已经登录的用户 访问登录页时url地址带from参数时 判断来源并跳转from地址  
              if (to.query && to.query.from) {
                // 测试环境 全媒体的测试用的qmt66.jiaoyu.cn 需要加上token参数
                if ((process.env.NODE_ENV == 'development' || process.env.VUE_APP_FLAG === 'test') && to.query.from.indexOf(".jiaoyu.cn") > -1) {
                  window.open(`${to.query.from}?api_token=${appsToken}`, "_self")
                } else {
                  window.open(to.query.from, "_self")
                }
              } else {
                next(`/${code}/worker`)
              }
            } else {
              next()
            }
          } else {
            next()
          }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})



export default router
