import services from '@/assets/js/services'
import utils from '../assets/js/utils'

export default {
  state: {
    useAppList: [], // 工作台 - 使用
    notUseApp: [], // 工作台 - 应用列表 未使用推荐
  },
  getters: {
  },
  actions: {
    // 工作台-获取应用列表
    requestSchoolAppList (context, payload) {
      return services.getSchoolAppList(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSchoolAppList', {
            'data': res.data ? res.data : null
          })
        }
        return res;
      })
    },
    // 修改个人信息
    requestUpdateUserInfo (context, payload) {
      return services.updateUserInfo(payload).then(res => {
        return res;
      })
    },
    // 绑定/换绑手机号
    requestUpdateUserPhone (context, payload) {
      return services.updateUserPhone(payload).then(res => {
        return res;
      })
    },
    // 解绑微信
    requestWechatUnbind (context, payload) {
      return services.getWechatUnbind(payload).then(res => {
        return res;
      })
    },

  },
  mutations: {
    setSchoolAppList (state, payload) {
      state.useAppList = []
      state.notUseApp = []
      if (!utils.isMobile()) {
        if (payload.data && payload.data.length > 0) {
          payload.data.forEach(element => {
            if (element.is_use == 1) {
              state.useAppList.push(element)
            } else {
              state.notUseApp.push(element)
            }
          });
        }
      } else {
        // H5只有这两个平台有,只展示这两个平台; 
        // 如果开通了在线学习，替换成学习中心;
        const mobileAppList = ["学习中心", "融媒运营平台",]
        const schoolCode = localStorage.getItem("schoolCode")
        const result = payload.data.filter(item => {
          if (item.is_use != 1) return // 如果未开通应用 return
          if (item.name.indexOf("在线学习") > -1) {
            item.name = "学习中心"
            item.url = `${services.XUEXI_SERVER}/center/${schoolCode}/user/myClass`
          }
          return mobileAppList.indexOf(item.name) > -1

        });
        state.useAppList = result
      }

    },
  }
}